import React, { FormEvent, useCallback, useState } from 'react';
import Modal from 'components/Modal';
import TextInput from './TextInput';

type Props = {
  isVisible: boolean;
  onConfirm: (code: string) => void;
  onCancel: () => void;
  invalid?: boolean;
  title?: string;
  message?: string;
};

export const OTPModal: React.FC<Props> = ({
  isVisible,
  onConfirm,
  onCancel,
  invalid,
  title = 'Verification Required',
  message = 'Please enter verification code',
}) => {
  const [code, setCode] = useState<string>('');

  const handleConfirm = useCallback(() => {
    onConfirm(code);
    setCode('');
  }, [code]);

  const handleCancel = useCallback(() => {
    setCode('');
    onCancel();
  }, []);

  const handleChangeText = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setCode(e.currentTarget.value);
    },
    [setCode]
  );

  return (
    <Modal isOpen={isVisible} onClose={handleCancel}>
      <div className="flex flex-col gap-5">
        <h2 className="text-lg text-primary">{title}</h2>

        <p className="text-center">{message}</p>

        <TextInput
          className="items-center space-x-3 text-center text-4xl tracking-widest"
          value={code}
          onChange={handleChangeText}
          maxLength={6}
          max={6}
          type="number"
          placeholder="* * * * * *"
        />

        {invalid ? (
          <h2 className="ml-3 text-center text-red-400">Invalid Code</h2>
        ) : null}
        <div className="flex flex-row gap-3">
          <button
            className="btn-main w-full"
            onClick={handleConfirm}
            disabled={!code || code.length < 6}
          >
            <h3 className="font-medium text-white">Confirm</h3>
          </button>

          <button
            className="btn-main w-full bg-gray-800 text-white"
            onClick={handleCancel}
          >
            <h3 className="font-medium text-white">Cancel</h3>
          </button>
        </div>
      </div>
    </Modal>
  );
};
