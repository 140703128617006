import React, { useCallback, useEffect } from 'react';

import heroimg from 'assets/images/upload-documents-banner.svg';
import styles from 'utils/styles';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { ROUTES } from 'routers/routes';
import { useUserRequests } from 'hooks/user-hooks';
import { useAmplifyAuth } from 'hooks/auth-hooks';

const VerificationPrompt = () => {
  const { doLoginNavigations } = useUserRequests();
  const { getAuth } = useAmplifyAuth();
  const navigate = useNavigate();

  useEffect(() => {
    guard();
  }, []);

  const guard = async () => {
    const authData = await getAuth();
    if (authData) {
      doLoginNavigations(true);
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  return (
    <div className="flex w-full flex-col py-5 px-6 sm:px-2 md:flex-row">
      <div className="flex flex-1 items-center justify-center">
        <img
          src={heroimg}
          alt="Hero"
          className="mb-2 flex h-auto w-auto max-w-[350px] self-center sm:mb-10"
        />
      </div>
      <div
        className={`flex flex-1 flex-col ${styles.flexCenter} mt-5 space-y-3 text-center sm:mt-0 sm:items-start sm:text-left`}
      >
        <div>
          <h1 className="text-3xl font-semibold text-secondary  sm:text-3xl">
            Next Steps
          </h1>
          <p className="mt-2 text-xl font-light">
            Please let us know on the next pages your:
          </p>
          <div className="mt-2 rounded-md bg-neutral-100 p-2 pl-8">
            <ul className="list-disc text-left">
              <li>Address</li>
              <li>100-Point Identification data</li>
              <li>Personal Bank Account details for settlements</li>
            </ul>
          </div>

          <p className="mt-4 text-sm font-light italic">
            You will require two of either{' '}
            <span className="font-semibold">
              {' '}
              Australian Passport, Drivers License{' '}
            </span>
            or <span className="font-semibold">Medicare Card</span> for the ID
            section.
          </p>
        </div>

        {/* Temporary navigate, replace with button type submit later on */}
        <Link to="/verification" className="w-full self-center sm:self-start">
          <button className="btn-main mt-8 w-full py-2">
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
        </Link>
        <Link to={ROUTES.HOME} className="w-full self-center sm:self-start">
          <button className="btn-main mt-1 w-full py-2">
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Skip</h2>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default VerificationPrompt;
