export const TransactionStatusExplainers: { [key: string]: string } = {
  'pending-buyer': 'This is a new transaction awaiting the seller to accept.',
  'pending-seller':
    'This is a new transaction that your customer wishes you to accept.',
  'accepted-buyer':
    // eslint-disable-next-line quotes
    "The seller has accepted this transaction and it is proceeding normally.\n\n REMEMBER - Don't settle until you receive your goods and/or services.",
  'accepted-seller': 'This is an active transaction with a customer.',
  'declined-buyer':
    'This transaction was declined by the seller. Please create a new transaction to reactivate.',
  'declined-seller':
    'This transaction was declined by you. Your customer has been informed of this. Please create a new transaction with them if this was done in error',
  cancelled:
    'This transaction has been cancelled and any GoEscrow held funds minus fees have been returned to the buyer.',
  'cancel-requested':
    'A party has requested this deal to be cancelled and we are awaiting confirmation. Sellers should not provide their goods/services if not yet done so or the parties should ensure goods/services are returned before accepting the cancellation request. GoEscrow is not liable for goods/service supply in the event that a transaction cancellation is accepted and funds are returned to the buyer.  Typically when a cancellation request is accepted it is after goods are returned to the seller',
  completed:
    'This transaction has completed as normal with GoEscrow held funds settled to the seller.',
  'in-dispute':
    'This transaction has been placed under administration by GoEscrow for dispute resolution and all GoEscrow funds will be held until the parties can mutually agree for an action. GoEscrow does not participate in the dispute negotiation process and remains impartial.',
  'contact-helpdesk':
    'This transaction is paused by GoEscrow administrative or compliance staff.\n\nPlease contact helpdesk@goescrow.com.au about this transaction',
  'public-trustee':
    'This transaction was not completed and funds were held IN ESCROW for 36 months and have now been forfeited to NSW Government Public Trustee',
  'settlement-requested':
    'Settlement request sent. Please wait while we work on final checks.',
};

export const PaymentStatusExplainers: { [key: string]: string } = {
  'pending-buyer':
    'It is ok for you now to fund this transaction into your GoEscrow holding account. Your payment will be not be sent to the seller until you authorise settlement. When we receive your payment we will instruct the seller to ship goods or provide your services.',
  'pending-seller':
    'Your customer has not yet funded this transaction by payment into their GoEscrow account. When payment is received we will notify you and the payment status will change.\n\nIMPORTANT - DO NOT SEND GOODS/SERVICES yet.',
  'partial-buyer':
    'We have received a partial payment for this transaction and await the remainder. When we receive your complete payment we will instruct the seller to ship goods or provide your services.',
  'partial-seller':
    'Your customer has partially paid for this transaction. We are awaiting complete payment and will notify you and update the payment status suitably when this occurs.\n\n IMPORTANT - DO NOT SEND GOODS/SERVICES yet.',
  cancelled: 'This transaction was cancelled before any payment',
  paid: 'This transaction is fully funded and monies are held in escrow for future settlement.\n\n IMPORTANT - It is now safe for all goods/services to be provided.',
  refunded:
    'Funds for this transaction have been returned to the buyer minus GoEscrow fees.',
  settled: 'The transaction has settled, finalised and completed.',
  unfunded:
    'This transaction was not funded by the buyer before the anytime settlement time occured.',
};
