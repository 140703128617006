import React, { useCallback, useMemo } from 'react';

import activeBG from 'assets/images/active-widget-bg.svg';
import recentBG from 'assets/images/recent-widget-bg.svg';
import styles from 'utils/styles';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ROUTES } from 'routers/routes';
import { format } from 'date-fns';
import { DateTimeFormats } from 'utils/parsers';
import { FiArrowRight } from 'react-icons/fi';

type Props = {
  status?: string;
  title?: string;
};

const ActiveDealsWidget: React.FC<Props> = ({ title, status }) => {
  const { getDeals, dealNavigationHelper } = useDealsRequests();
  const { getUserDetails } = useUserRequests();
  const navigate = useNavigate();
  const { data: dealsRes, isLoading } = useQuery(
    ['dealsList', title, status],
    () => getDeals({ status, per_page: 3 }),
    { refetchOnWindowFocus: true }
  );

  const { data: userRes } = useQuery(['userDetails'], getUserDetails);

  const dealsData = useMemo(
    () => (dealsRes && dealsRes.status ? dealsRes?.data : []),
    [dealsRes]
  );
  const userDetails = useMemo(
    () => (userRes && userRes.status ? userRes?.data : {}),
    [userRes]
  );

  const isRecentTransactions = useMemo(
    () => title === 'Recent Transactions',
    [title]
  );

  const handleViewDeal = useCallback(
    (deal: any) => () => {
      dealNavigationHelper(deal, userDetails, false);
    },
    [userDetails]
  );

  const goToAllDeals = useCallback(() => {
    navigate(ROUTES.TRANSACTIONS);
  }, []);

  const renderItem = (item: any, index: any) => {
    let transactorType = 'Buyer';

    if (item.initiator && item.initiator.toLowerCase() === 'me') {
      transactorType = item.transaction_type === 'send' ? 'Buyer' : 'Seller';
    } else {
      transactorType = item.transaction_type === 'send' ? 'Seller' : 'Buyer';
    }

    return (
      <button
        key={item.reference}
        className="flex w-full flex-row justify-between rounded-sm bg-white p-3 drop-shadow-lg"
        type="button"
        onClick={handleViewDeal(item)}
      >
        <div className="flex flex-col items-start">
          <p>
            {item?.reference} - {transactorType}
          </p>
          <p className="text-sm text-primary">
            ${parseFloat(item?.amount)} -{' '}
            {(item?.payment_status === 'paid'
              ? 'IN ESCROW'
              : item?.payment_status ?? ''
            ).toUpperCase()}
          </p>
        </div>
        <div className="flex flex-col items-end text-sm">
          <p>
            {format(
              new Date(item?.created_at),
              DateTimeFormats.DisplayDateTimeShort
            )}
          </p>
          <p className="text-sm text-primary">
            {item?.public_status?.toUpperCase()}
          </p>
        </div>
      </button>
    );
  };

  return (
    <div className="flex min-w-[300px] flex-col items-stretch justify-between space-y-3 rounded-sm bg-white px-4 pt-3 pb-5 drop-shadow-xl sm:h-[300px]">
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-center text-lg font-semibold text-black">
          {title || 'Active Deals'}
        </h3>
        <Link to={ROUTES.TRANSACTIONS}>
          <h3 className="flex flex-row items-center gap-2 text-center font-semibold text-primary">
            See all <FiArrowRight />
          </h3>
        </Link>
      </div>
      {!dealsData || dealsData.length <= 0 || isLoading ? (
        <>
          {isRecentTransactions ? (
            <div className="flex flex-1 items-center justify-center">
              <h4 className="text-center text-secondary-120">
                {isRecentTransactions
                  ? 'No recent transactions to display'
                  : 'No active transactions at this moment.'}
              </h4>
            </div>
          ) : (
            <>
              <img
                src={activeBG}
                alt="No Deals"
                className={`${styles.fullImage} max-h-[150px] px-5`}
              />
              <h4 className="text-center text-secondary-120">
                {isRecentTransactions
                  ? 'No recent transactions to display'
                  : 'No active transactions at this moment.'}
              </h4>
            </>
          )}
        </>
      ) : (
        <div className="flex flex-1 flex-col gap-2">
          {isRecentTransactions
            ? dealsData.slice(0, 3).map(renderItem)
            : dealsData.map(renderItem)}
        </div>
      )}
    </div>
  );
};

export default ActiveDealsWidget;
