/* eslint-disable camelcase */
import { useQuery } from '@tanstack/react-query';
import BankDetails from 'components/forms/BankDetails';
import BasicDetails from 'components/forms/BasicDetails';
import DocumentDetails from 'components/forms/DocumentDetails';
import { USER_DETAILS_TYPES } from 'constants/enums';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toastError } from 'utils/toast';

const ViewDetails = () => {
  const {
    getUserDetails,
    updateUserBankDetails,
    updateUserDetails,
    updateUserAddress,
    updateUserIdentification,
    verifyUserBankDetails,
  } = useUserRequests();
  const { dealNavigationHelper } = useDealsRequests();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<'edit' | 'view'>();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const urlType = searchParams.get('type') || undefined;
  const urlMode = searchParams.get('mode') || undefined;

  const type = urlType || USER_DETAILS_TYPES.ACCOUNT;

  const mode = useMemo(
    () => editMode || urlMode || 'view',
    [editMode, urlMode]
  );

  const deal = location?.state?.deal || {};

  const { data: userData, refetch } = useQuery(
    ['userDetails'],
    getUserDetails,
    {
      refetchInterval: false,
    }
  );

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const submitBasicDetails = async (data: any) => {
    // TODO: Add this call
    // {
    //   email,
    //   name: `${first_name} ${last_name}`,
    //   given_name: first_name,
    //   family_name: last_name,
    // }
    // const resAws = await updateUserAttributes(
    //   pick(data, ['first_name', 'last_name', 'email', 'birth_date']),
    // );
    // if (!resAws) {
    //   setIsSubmitting(false);
    //   return;
    // }
    const resBasic = await updateUserDetails(data.basicDetails);
    const resAddress = await updateUserAddress(data.address);
    if (resBasic.status && resAddress.status) {
      refetch();
      setEditMode('view');
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);

      if (!resBasic.status && resBasic.data) {
        toastError(`Error: ${resBasic.data}`);
        toastError(`Error: ${resBasic.data}`);
      } else if (!resAddress.status && resAddress.data) {
        toastError(`Error: ${resAddress.data}`);
      } else {
        toastError();
      }
    }
  };

  const submitDocumentDetails = async (data: any) => {
    setIsSubmitting(true);
    const res = await updateUserIdentification(data);
    if (res.status) {
      refetch();
      setEditMode('view');
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      if (res.data) {
        toastError(`Error: ${res.data}`);
      } else {
        toastError();
      }
    }
  };

  const submitBankDetails = async (data: any) => {
    setIsSubmitting(true);
    const res = await updateUserBankDetails(data);
    if (res && res.status) {
      refetch();
      if (deal && deal?.id) {
        setTimeout(() => {
          setIsSubmitting(false);
          userDetails.bank_account = res.data;

          dealNavigationHelper(deal, userDetails, true);
        }, 500);
      } else {
        setEditMode('view');
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
      if (res.data) {
        toastError(`Error: ${res.data}`);
      } else {
        toastError();
      }
    }
  };

  const verifyBankDetails = async (verification_code: string) => {
    setIsSubmitting(true);
    const res = await verifyUserBankDetails(verification_code);
    setIsSubmitting(false);
    if (res.status) {
      refetch();
      setEditMode('view');
    } else {
      if (res.data) {
        toastError(`Error: ${res.data}`);
      } else {
        toastError();
      }
    }
  };

  const selectedData = useMemo(() => {
    switch (type) {
      case USER_DETAILS_TYPES.ACCOUNT: {
        return {
          ...userDetails.address,
          email: userDetails.email || '',
          first_name: userDetails.first_name || '',
          last_name: userDetails.last_name || '',
          phone: userDetails.phone || '',
          reference: userDetails.reference || '',
          birth_date: userDetails.birth_date
            ? new Date(userDetails.birth_date)
            : new Date(),
        };
      }
      case USER_DETAILS_TYPES.BANK: {
        if (userDetails.bank_account) {
          const { account_number, bsb_number, is_verified, account_name } =
            userDetails.bank_account;
          return {
            bsb_number: bsb_number.replace(/-/g, ''),
            account_number,
            account_name,
            is_verified,
          };
        } else return {};
      }
      case USER_DETAILS_TYPES.IDENTIFICATION: {
        return {
          driver_license: userDetails.driver_license,
          medicare: userDetails.medicare,
          passport: userDetails.passport,
        };
      }
    }
  }, [userDetails, type]);

  const Title = useMemo(() => {
    switch (type) {
      case USER_DETAILS_TYPES.ACCOUNT:
        return 'Personal Information';
      case USER_DETAILS_TYPES.BANK:
        return 'Bank Details';
      case USER_DETAILS_TYPES.IDENTIFICATION:
        return 'Identification Documents';
    }
  }, [type]);

  const Content = useMemo(() => {
    switch (type) {
      case USER_DETAILS_TYPES.ACCOUNT:
        if (mode === 'edit') {
          return (
            <BasicDetails
              data={selectedData}
              onContinue={submitBasicDetails}
              isUpdate
            />
          );
        }
        return <BasicDetails readonly data={selectedData} isUpdate />;
      case USER_DETAILS_TYPES.BANK:
        if (mode === 'edit') {
          return (
            <BankDetails
              data={selectedData}
              onSubmit={submitBankDetails}
              onVerify={verifyBankDetails}
              setEditMode={setEditMode}
              isUpdate
            />
          );
        }
        return (
          <BankDetails
            readonly
            data={selectedData}
            setEditMode={setEditMode}
            isUpdate
            onVerify={verifyBankDetails}
          />
        );
      case USER_DETAILS_TYPES.IDENTIFICATION:
        if (mode === 'edit') {
          return (
            <DocumentDetails refetch={refetch} data={selectedData} isUpdate />
          );
        }
        return <DocumentDetails readonly data={selectedData} isUpdate />;
      default:
        return <div />;
    }
  }, [type, selectedData, mode]);

  const enableEdit = useCallback(() => {
    setEditMode('edit');
  }, []);

  const cancelledit = useCallback(() => {
    setEditMode('view');
  }, []);

  const EditButton = useMemo(() => {
    if (
      type === USER_DETAILS_TYPES.BANK &&
      selectedData.bsb_number &&
      !selectedData.is_verified
    ) {
      return <></>;
    } else {
      if (editMode !== 'edit') {
        return (
          <button onClick={enableEdit}>
            <h4 className="font-semibold text-primary">Edit</h4>
          </button>
        );
      }
      return (
        <button onClick={cancelledit}>
          <h4 className="font-semibold text-primary">Cancel</h4>
        </button>
      );
    }
  }, [editMode, cancelledit, type, selectedData]);

  return (
    <div className="flex h-full w-full flex-col bg-white p-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-xl font-semibold">Account Details</h1>
        {EditButton}
      </div>

      {/* TODO: Temp */}
      <h2 className="font-semibold text-primary">{Title}</h2>
      {Content}
    </div>
  );
};

export default ViewDetails;
