import React, { useCallback, useEffect, useState } from 'react';
import Countdown, {
  CountdownRendererFn,
  CountdownRenderProps,
} from 'react-countdown';
import { MdTimer } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';

type TimerProps = {
  date?: Date | string;
  render?: CountdownRendererFn;
  setComplete?: (val: boolean) => void;
};

export const Timer: React.FC<TimerProps> = ({ date, render, setComplete }) => {
  const [count, setCount] = useState(date);
  const [reset, setReset] = useState('');

  useEffect(() => {
    if (date) {
      setCount(date);
      setReset(date.toString());
    }
  }, [date]);

  const handleComplete = useCallback(() => {
    if (setComplete) setComplete(true);
  }, [setComplete]);

  return (
    <Countdown
      date={count}
      key={reset}
      renderer={RenderCountdown}
      onComplete={handleComplete}
    />
  );
};

const RenderCountdown: React.FC<CountdownRenderProps> = ({
  formatted,
  completed,
}) => {
  const { minutes, seconds } = formatted;
  return (
    <div
      className={'flex-row items-center text-center font-semibold text-primary'}
    >
      <p>
        <MdTimer className="mr-2 inline-block text-lg" />
        {completed ? 'Resend Code' : `Re-send code in ${minutes}:${seconds}`}
      </p>
    </div>
  );
};
