import { Dialog, Disclosure } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { formatInTimeZone } from 'date-fns-tz';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import { DateTimeFormats } from 'utils/parsers';
import { toastError, toastSuccess } from 'utils/toast';
import Modal from './Modal';

type Props = {
  notes: any[];
  refetch: () => void;
  dealId: string;
};

const NotesSection: React.FC<Props> = ({ notes, dealId, refetch }) => {
  const [isAddModalShown, setShowAddModal] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSharedNote, setSharedNote] = useState<boolean>(false);
  const { getUserDetails } = useUserRequests();
  const { addDealNote, deleteDealNote } = useDealsRequests();

  const { data: userData } = useQuery(['userDetails'], getUserDetails, {
    refetchInterval: false,
  });
  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const addNote = useCallback(async () => {
    setIsSubmitting(true);
    const res = await addDealNote(
      { note: noteText, visibility: isSharedNote ? 'public' : 'private' },
      dealId
    );
    setIsSubmitting(false);

    if (res.status) {
      toastSuccess('Note Added!');
      refetch();
      handleCloseAddModal();
    } else {
      toastError();
    }
  }, [noteText, isSharedNote]);

  const deleteNote = useCallback(
    (noteId: any) => async () => {
      setIsSubmitting(true);
      const res = await deleteDealNote(noteId, dealId);
      setIsSubmitting(false);

      if (res.status) {
        refetch();
      } else {
        toastError();
      }
    },
    [dealId]
  );

  const showAddNoteModal = useCallback(() => {
    setShowAddModal(true);
  }, []);

  const handleCloseAddModal = useCallback(() => {
    setNoteText('');
    setShowAddModal(false);
  }, []);

  const toggleSharedNote = useCallback(() => {
    setSharedNote(!isSharedNote);
  }, [isSharedNote]);
  const NotesList = useMemo(
    () => (
      <div className="flex w-full flex-col justify-around">
        {notes.map((note) => {
          const isMyNote = note.user_id === userDetails.id;
          return (
            <div
              className="rounded-sm border-l-2 border-l-primary bg-white p-2 shadow-md"
              key={note.id}
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-1 flex-col justify-between">
                  <p>{note.note}</p>
                  <p className="mt-1 text-sm">
                    {formatInTimeZone(
                      note.created_at,
                      'UTC',
                      DateTimeFormats.DisplayDateShort
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-end justify-between">
                  {isMyNote && (
                    <button onClick={deleteNote(note.id)}>
                      <FiTrash className="text-primary" />
                    </button>
                  )}
                  <div className="mt-1 flex flex-col items-end">
                    <p className="text-primary">
                      {isMyNote ? 'You' : 'Other Party'}
                    </p>
                    <p>{note.visibility == 'private' ? 'Private' : 'Shared'}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <button disabled={isSubmitting} onClick={showAddNoteModal}>
          <div className="mt-4 flex flex-row self-center">
            <BiPlusCircle className="mr-2 text-primary" />
            <p className="text-primary">ADD NEW NOTE</p>
          </div>
        </button>
      </div>
    ),
    [notes, dealId, deleteNote, userDetails]
  );

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <div className="flex flex-col justify-between">
            <Disclosure.Button className="flex flex-1 items-center px-3 py-2 text-left">
              <span className="text-lg font-semibold text-primary">Notes</span>
              {!open ? (
                <BsChevronDown className="ml-auto text-lg font-semibold text-primary" />
              ) : (
                <BsChevronUp className="ml-auto text-lg font-semibold text-primary" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="flex flex-1 flex-col items-center px-3 py-2">
              {notes && notes.length > 0 ? (
                NotesList
              ) : (
                <>
                  <p className="text-center text-lg">No notes for this deal.</p>
                  <button
                    className="px-3 py-2 text-primary"
                    disabled={isSubmitting}
                    onClick={showAddNoteModal}
                  >
                    Add now
                  </button>
                </>
              )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      <Modal isOpen={isAddModalShown} onClose={handleCloseAddModal}>
        <Dialog.Title
          as="h3"
          className="text-lg font-semibold leading-6 text-primary"
        >
          Add Note
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <div>
            <textarea
              name="notes"
              rows={6}
              placeholder="New Note..."
              className="w-full focus:border-0 focus:outline-none active:border-0"
              value={noteText}
              onChange={(e) => {
                setNoteText(e.target.value);
              }}
              disabled={isSubmitting}
            />
          </div>

          <div className="mt-2 flex flex-row items-center gap-2">
            <input
              id="shareNote"
              type={'checkbox'}
              className="h-4 w-4 border-2"
              checked={isSharedNote}
              onChange={toggleSharedNote}
            />
            <label htmlFor="shareNote">Share note with other party</label>
          </div>

          <div className="mt-2 flex w-full flex-row items-center justify-end gap-3">
            <button
              className="px-4 py-3 font-semibold text-primary"
              onClick={handleCloseAddModal}
            >
              Cancel
            </button>
            <button
              className="btn-main"
              onClick={addNote}
              disabled={isSubmitting || !noteText || noteText.length < 3}
            >
              Add Note
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotesSection;
