import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

import TextInput from 'components/TextInput';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { toastError, toastSuccess } from 'utils/toast';
import { OTPModal } from 'components/OTPModal';
import phone from 'phone';

type Props = {
  mode: 'mobile' | 'email';
};

const ChangeEmailMobile: React.FC<Props> = ({ mode }) => {
  const [newFieldText, setNewFieldText] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showOTPModal, setShowOTPModal] = useState<boolean>(false);
  const [isOTPInvalid, setOTPInvalid] = useState<boolean>(false);

  const {
    updateUserDetails,
    lookupGoEscrowUser,
    getUserDetails,
    updateEmail,
    checkEmailExists,
    updatePhone,
  } = useUserRequests();
  const { updateUserAttributes, verifyUpdateVerification } = useAmplifyAuth();

  const {
    data: userData,
    refetch,
    isLoading,
  } = useQuery(['userDetails'], getUserDetails, { refetchInterval: false });

  const isEmail = useMemo(() => mode === 'email', [mode]);

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const currentData = useMemo(() => {
    if (isEmail) return userDetails.email || '';
    return userDetails.phone || '';
  }, [userDetails, mode]);

  const isSubmittable = useMemo(() => {
    if (isEmpty(newFieldText)) return false;
    if (isEmail) {
      const emailSchema = yup.string().email();
      try {
        emailSchema.validateSync(newFieldText);
        return true;
      } catch (e) {
        return false;
      }
    } else {
      const phoneScheme = yup
        .string()
        .min(4)
        .max(15)
        .matches(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/g);
      try {
        phoneScheme.validateSync(newFieldText);
        return true;
      } catch (e) {
        return false;
      }
    }
  }, [mode, newFieldText]);

  const syncBasicDetails = useCallback(async () => {
    const basicDetails = isEmail
      ? { email: newFieldText }
      : { phone: newFieldText };

    const resBasic = await updateUserDetails(basicDetails);

    if (resBasic.status) {
      refetch();
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);

      if (resBasic.data) {
        toastError(`Error: ${resBasic.data}`);
      } else {
        toastError();
      }
    }
  }, [newFieldText, mode, userDetails]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);

    if (mode === 'email') {
      const emailExists = await checkEmailExists(newFieldText);

      if (emailExists.data) {
        toastError('Email already exists');
        setIsSubmitting(false);
        return;
      }

      const resAws = await updateUserAttributes({
        email: newFieldText.toLowerCase(),
      });
      setIsSubmitting(false);
      if (resAws) {
        setShowOTPModal(true);
        // Show modal
        return;
      }
      toastError();
      return;
    }

    const resPhone = await updatePhone(parseInputIfPhone(newFieldText));

    setIsSubmitting(false);
    if (resPhone.data) {
      if (resPhone.data.phone_exists) {
        toastError('Phone number already exists');
        return;
      }

      setShowOTPModal(true);
      // Show modal
      return;
    }

    toastError();
    return;
  }, [newFieldText, mode]);

  const handleConfirmModal = useCallback(
    async (value: string) => {
      setOTPInvalid(false);
      setIsSubmitting(true);
      if (mode === 'email') {
        const resAws = await verifyUpdateVerification(value, 'email');
        if (resAws) {
          setShowOTPModal(false);

          // await syncBasicDetails();
          await updateEmail(newFieldText);

          setIsSubmitting(false);
          setNewFieldText('');
          toastSuccess();
          refetch();
          return;
        }
        setIsSubmitting(false);
        setOTPInvalid(true);
        return;
      } else {
        const resPhone = await updatePhone(
          parseInputIfPhone(newFieldText),
          value
        );

        setIsSubmitting(false);
        if (resPhone.data) {
          setShowOTPModal(false);

          if (resPhone.data.two_factor_invalid) {
            setOTPInvalid(true);
            return;
          }

          setNewFieldText('');
          toastSuccess();
          refetch();
          return;
        }

        toastError();
        return;
        // const resAws = await verifyUpdateVerification(value, 'phone_number');
        // if (resAws) {
        //   setShowOTPModal(false);

        //   // API sync
        //   await syncBasicDetails();
        //   setNewFieldText('');
        //   toastSuccess();
        //   refetch();
        //   return;
        // }
        // setIsSubmitting(false);
        // setOTPInvalid(true);
        // return;
      }
    },
    [mode, refetch, newFieldText]
  );

  const handleCancelModal = useCallback(() => {
    setShowOTPModal(false);
  }, []);

  const parseInputIfPhone = (input: string) => {
    if (input && input !== '' && input.match(/^[0-9]{8,}$/)) {
      return (
        phone(input, {
          country: 'AUS',
        }).phoneNumber ?? input
      );
    }
    return input;
  };

  return (
    <div className="flex h-full w-full flex-col items-center gap-4 bg-white py-10 px-3">
      <h2 className="text-center text-xl font-semibold">
        Change {isEmail ? 'Email Address' : 'Mobile Number'}
      </h2>

      <div className="flex flex-col gap-4 sm:w-1/2">
        <div className="flex flex-col items-center sm:flex-row">
          <p>Current {mode === 'email' ? 'Email Address' : 'Mobile Number'}:</p>
          <p className="ml-2 text-primary">{currentData.toLowerCase()}</p>
        </div>
        <div className="flex flex-col gap-2">
          <TextInput
            value={newFieldText}
            onChange={(e) => {
              setNewFieldText(e.currentTarget.value);
            }}
            disabled={isSubmitting}
            name="new_field"
            placeholder={`New ${isEmail ? 'Email Address' : 'Mobile Number'}`}
            type={isEmail ? 'email' : 'number'}
          />
        </div>

        <button
          className="btn-main"
          disabled={isSubmitting || !isSubmittable}
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
      <OTPModal
        isVisible={showOTPModal}
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}
        invalid={isOTPInvalid}
        message={`Please enter verification code sent to your ${
          mode === 'email' ? 'email address' : 'phone number'
        }`}
      />
    </div>
  );
};

export default ChangeEmailMobile;
