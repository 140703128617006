/* eslint-disable camelcase */
import axiosInstance from 'api';
import { API_ENDPOINTS } from 'constants/api';
import {
  UserAddressForm,
  UserBankDetailsForm,
  UserDetailsForm,
  UserIdentificationForm,
} from 'type/user-dto';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';
import { checkSufficientIdentification } from 'utils/helpers';

export const useUserRequests = () => {
  const { signOut } = useAmplifyAuth();
  const navigate = useNavigate();

  const createUser = async () => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_CREATE
    );

    return { data, status };
  };

  const getUserDetails = async () => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_DETAILS
    );
    return { data, status };
  };

  const updateUserDetails = async (form: UserDetailsForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_BASIC_DETAILS,
      form
    );
    return { data, status };
  };

  const updateUserAddress = async (form: UserAddressForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_ADDRESS,
      form
    );
    return { data, status };
  };

  const updateUserIdentification = async (form: UserIdentificationForm) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_IDENTITY,
      form
    );
    return { data, status };
  };

  const updateUserBankDetails = async (form: UserBankDetailsForm) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_BANK_DETAILS,
      form
    );
    return { data, status };
  };

  const getUserPaymentDetails = async () => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_PAYMENT
    );
    return { data, status };
  };

  const doCreateAccountCircleBack = (repeat?: number) =>
    setTimeout(async () => {
      try {
        const res = await getUserDetails();
        await createUser();

        if (res && res.status) {
          doLoginNavigations();
        } else {
          throw res.data;
        }
      } catch (e) {
        if (repeat && repeat >= 3) {
          await signOut();
          navigate(ROUTES.LOGIN);
          toastError();
        } else {
          doCreateAccountCircleBack(repeat ? (repeat += 1) : 1);
        }
      }
    }, 2000);

  const doLoginNavigations = async (skipNavigate?: boolean) => {
    getUserDetails()
      .then((userDetails) => {
        // if backend user data is invalid, navigate to login
        if (!userDetails.status) {
          throw userDetails.data;
        } else if (!userDetails.data.mobile_verified) {
          navigate(ROUTES.TWO_FACTOR_MOBILE);
        }
        // } else if (
        //   !userDetails.data.birth_date ||
        //   !userDetails.data.address ||
        //   !checkSufficientIdentification(userDetails.data)
        // ) {
        //   navigate(ROUTES.VERIFICATION_PROMPT);

        //   // If everything is all set, navigate to home
        // } else if (
        //   (userDetails.data.birth_date,
        //   userDetails.data.address,
        //   checkSufficientIdentification(userDetails.data))
        // )
        if (skipNavigate) return;
        navigate(ROUTES.HOME);
      })
      .catch(async (error) => {
        if (error && error.code === 'ERR_BAD_REQUEST') {
          doCreateAccountCircleBack(0);
        } else {
          const res = await signOut();
          if (res) {
            navigate(ROUTES.LOGIN);
          } else {
            toastError();
          }
        }
      });
  };

  const lookupGoEscrowUser = async (goEscrowId: string) => {
    const { data, status } = await axiosInstance.get(
      `${API_ENDPOINTS.USER_LOOKUP}/${goEscrowId}`
    );
    return { data, status };
  };

  const inviteUser = async (form: any) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_INVITE,
      form
    );
    return { data, status };
  };

  const uploadDriverLicense = async (form: any) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.DRIVER_LICENSE,
      form
    );
    return { data, status };
  };

  const uploadMedicare = async (form: any) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.MEDICARE,
      form
    );
    return { data, status };
  };

  const uploadPassport = async (form: any) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.PASSPORT,
      form
    );
    return { data, status };
  };

  const checkEmailExists = async (email: string) => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_EMAIL_EXISTS,
      {
        params: {
          email: email,
        },
      }
    );
    return { data, status };
  };

  const updateEmail = async (email: string) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_UPDATE_EMAIL,
      {
        email: email,
      }
    );
    return { data, status };
  };

  const updatePhone = async (phone: string, two_factor_code?: string) => {
    const { data, status } = await axiosInstance.put(
      API_ENDPOINTS.USER_UPDATE_PHONE,
      {
        phone: phone,
        two_factor_code: two_factor_code || '',
      }
    );
    return { data, status };
  };

  const verifyPhone = async (two_factor_code?: string) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_VERIFY_PHONE,
      two_factor_code
        ? {
            two_factor_code,
          }
        : {}
    );
    return { data, status };
  };

  const forgotPassword = async (email: string) => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.USER_FORGOT_PASSWORD,
      {
        params: {
          email: email,
        },
      }
    );
  };

  const verifyUserBankDetails = async (verification_code?: string) => {
    const { data, status } = await axiosInstance.post(
      API_ENDPOINTS.USER_VERIFY_BANK,
      verification_code
        ? {
            verification_code,
          }
        : {}
    );
    return { data, status };
  };

  return {
    forgotPassword,
    updatePhone,
    updateEmail,
    checkEmailExists,
    createUser,
    getUserDetails,
    updateUserDetails,
    updateUserAddress,
    updateUserIdentification,
    updateUserBankDetails,
    verifyUserBankDetails,
    getUserPaymentDetails,
    doLoginNavigations,
    lookupGoEscrowUser,
    inviteUser,
    uploadDriverLicense,
    uploadMedicare,
    uploadPassport,
    verifyPhone,
  };
};
