import { Dialog } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import Modal from 'components/Modal';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TransferPayment = () => {
  const navigate = useNavigate();
  const { getUserPaymentDetails } = useUserRequests();
  const location = useLocation();
  const { getUserDetails } = useUserRequests();
  // const [showPoliModal, setShowPoliModal] = useState<boolean>(false);
  // const [showPoliDisclaimer, setShowPoliDisclaimer] = useState<boolean>(false);
  // const [poliUrl, setPoliUrl] = useState<string>('');
  const [showBsbAccountModal, setShowBsbAccountModal] =
    useState<boolean>(false);
  const [showPayIdModal, setShowPayIdModal] = useState<boolean>(false);
  // TODO: Temporary workaround for resetting location state issue
  const [dealId, setDealId] = useState<string>(location.state.dealId);
  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const { getDealDetails, dealNavigationHelper, bypassPayment, payByPoli } =
    useDealsRequests();

  // const { data: paymentData, isLoading: paymentLoading } = useQuery(
  //   ['userPaymentDetails'],
  //   getUserPaymentDetails
  // );

  const {
    data: dealData,
    isLoading: dealLoading,
    refetch,
  } = useQuery(['dealDetails', dealId], () => getDealDetails(dealId));

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );

  const isLoading = useMemo(() => dealLoading, [dealLoading]);

  const hasToInvite = useMemo(
    () =>
      dealDetails &&
      dealDetails.initiator === 'me' &&
      !dealDetails?.other_user &&
      !dealDetails?.match,
    [dealDetails]
  );

  useEffect(() => {
    if (!['pending', 'partial'].includes(dealDetails?.payment_status))
      dealNavigationHelper(dealDetails, userDetails, true);
  }, [dealDetails, userDetails]);

  // const handleBypassPay = useCallback(async () => {
  //   const res = await bypassPayment(dealId);
  //   if (res.status) {
  //     refetch();
  //   } else {
  //     toastError();
  //   }
  // }, [dealId]);

  const handlePayByPoli = useCallback(async () => {
    const res = await payByPoli(dealId);

    if (res?.status && res?.data) {
      window.location.href = res?.data;
    }
  }, [dealId]);

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center gap-4 bg-white">
      {/* {userDetails &&
      (!userDetails.bank_account || !userDetails.bank_account.bsb_number) ? (
        <>
          <div className="absolute top-0 mb-28 min-h-[30px] w-full bg-yellow-500 py-3 pl-4 pr-2">
            <p className="text-white">
              We&apos;re still setting up your GoESCROW Bank Account.
            </p>
          </div>
          <div className=" h-28 sm:h-32 md:h-14" />
        </>
      ) : null} */}
      <div className="flex flex-col gap-3">
        <h2 className="text-center text-xl text-primary">
          Send Funds into Escrow
        </h2>
        <p className="text-center text-sm">
          Please transfer your payment into the GoESCROW holding account using
          the transaction ID as the Internet Banking reference.
          <br />
          On cleared receipt the payment status will change to “In Escrow” and
          the seller will be notified.
        </p>
      </div>

      <div className="my-4 flex flex-col gap-5 lg:flex-row">
        <div className="flex flex-col gap-3">
          <h2 className="text-center text-lg font-semibold">
            EFT Deposit Details
          </h2>

          <div className="justify-center rounded-md bg-neutral-100 py-6 px-10 shadow-md">
            <p>
              Bank Account Name:{' '}
              <span className="text-primary">GoEscrow Pty Ltd</span>
            </p>
            <p>
              Care of:{' '}
              <span className="text-primary">
                Commonwealth Bank of Australia
              </span>
            </p>
            <p>
              SWIFT Code: <span className="text-primary">CTBAAU2S</span>
            </p>
            <p className="font-semibold">
              Bank Account BSB: <span className="text-primary">064 152</span>
            </p>
            <p className="font-semibold">
              Account Number: <span className="text-primary">1045 9623</span>
            </p>
            <p className="font-semibold">
              Deposit Amount:{' '}
              <span className="text-primary">
                ${parseFloat(dealDetails?.remaining_payment_amount).toFixed(2)}
              </span>
            </p>
            <p className="font-semibold">
              Transaction Reference:{' '}
              <span className="text-primary">{dealDetails?.reference}</span>
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h2 className="text-center text-lg font-semibold">
            PayID Deposit Details
          </h2>

          <div className="justify-center rounded-md bg-neutral-100 py-6 px-10 shadow-md">
            <p>
              ACN PayID: <span className="text-primary">650 709 354</span>
            </p>
            <p className="font-semibold">
              Deposit Amount:{' '}
              <span className="text-primary">
                ${parseFloat(dealDetails?.remaining_payment_amount).toFixed(2)}
              </span>
            </p>
            <p className="font-semibold">
              PayID Description:{' '}
              <span className="text-primary">{dealDetails?.reference}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="mx-6 mb-5 flex flex-col">
        <p className="text-center text-primary">NOTES:</p>
        <p className="text-center text-sm">
          For Anytime Escrow transactions - you will be required to authorise
          final settlement to the seller after you have received your goods or
          services.
          <br />
          <br />
          For Timed Escrow transactions - settlement will be automatic unless
          you lodge a dispute to pause the transaction. If both you and the
          seller agree funds held in escrow can be reversed back to you.
        </p>
      </div>

      {/* <div>
        <button className="btn-main" onClick={handleBypassPay}>
          Dev Bypass Payment
        </button>
      </div> */}

      {/* BSB MOdal */}
      <Modal
        isOpen={showBsbAccountModal}
        onClose={() => setShowBsbAccountModal(false)}
      >
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          Transfer Amount to GoESCROW
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p className="text-center text-sm">
            Trust Account for this Transaction via BSB and Account Number
          </p>

          <div className="flex items-center justify-center rounded-lg bg-neutral-200 py-3 px-4  text-start">
            <div className="flex flex-col items-start justify-center text-start">
              <div className="inline-block">
                Send{'  '}
                <span className="font-bold text-primary">
                  $
                  {parseFloat(dealDetails?.remaining_payment_amount).toFixed(2)}
                </span>
              </div>
              <div className="inline-block">
                BSB:{'  '}
                <span className="font-bold text-primary">111-111</span>
              </div>
              <div className="inline-block">
                ACC:{'  '}
                <span className="font-bold text-primary">1234-4567</span>
              </div>
              <div className="inline-block">
                NAME:{'  '}
                <span className="font-bold text-primary">
                  GoESCROW Trust Account
                </span>
              </div>
              <div className="inline-block">
                REF:{'  '}
                <span className="font-bold text-primary">
                  {dealDetails?.reference}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-1">
            <button
              onClick={() => setShowBsbAccountModal(false)}
              className="w-full rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* PayID MOdal */}
      <Modal isOpen={showPayIdModal} onClose={() => setShowPayIdModal(false)}>
        <Dialog.Title
          as="h3"
          className="text-center text-lg font-medium leading-6 text-primary"
        >
          Transfer Amount to GoESCROW
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p className="text-center text-sm">
            Trust Account for this Transaction via PayID
          </p>

          <div className="flex items-center justify-center rounded-lg bg-neutral-200 py-3 px-4">
            <div className="inline-block justify-center text-center">
              Send{' '}
              <span className="font-semibold text-primary">
                ${parseFloat(dealDetails?.remaining_payment_amount).toFixed(2)}
              </span>{' '}
              to{' '}
              <span className="font-semibold text-primary">
                {' '}
                payid@goescrow.com.au
              </span>
              <br />
              with reference{' '}
              <span className="font-semibold text-primary">
                {dealDetails?.reference}
              </span>
            </div>
          </div>
          <div className="mt-8 flex flex-1">
            <button
              onClick={() => setShowPayIdModal(false)}
              className="w-full rounded border-2 border-primary py-2 px-4 font-semibold text-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransferPayment;
