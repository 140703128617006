import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import logo from 'assets/images/logo-full-horizontal.svg';
import { useUserRequests } from 'hooks/user-hooks';
import { useCallback, useMemo } from 'react';
import { getinitials } from 'utils/parsers';
import SidebarWidget from 'components/widgets/SidebarWidget';
import { FiArrowLeft } from 'react-icons/fi';

const Main = () => {
  const { getUserDetails } = useUserRequests();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: userData } = useQuery(['userDetails'], getUserDetails, {
    refetchInterval: false,
  });

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const fullname = useMemo(() => {
    if (!userDetails) return '';
    return `${userDetails.first_name} ${userDetails.last_name}`;
  }, [userDetails]);

  const initials = useMemo(() => {
    return getinitials(fullname);
  }, [fullname]);

  const isNotHome = useMemo(() => {
    if (pathname && pathname?.length > 1) {
      return pathname === '/home' ? false : true;
    }
    return false;
  }, [pathname]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="flex h-auto min-h-screen flex-1 flex-col items-center bg-mainBG pt-0 pb-10 sm:pt-3 md:pb-0">
      <div className="relative flex w-11/12 flex-1 flex-col items-stretch self-center sm:max-w-[1080px]">
        <div className="flex w-full flex-row items-center justify-between">
          <Link to="/">
            <img src={logo} alt="GoEscrow Logo" className="my-5 flex h-8" />
          </Link>
          <div className="flex flex-row items-center space-x-5">
            {/* <button type="button">
              <BiSearch className="hidden text-2xl text-gray-500 sm:flex" />
              </button>
              <button type="button">
              <BiBell className="hidden text-2xl text-gray-500 sm:flex" />
            </button> */}
            <div className="flex cursor-default flex-row items-center space-x-1">
              <div className="relative flex items-center justify-center rounded-full bg-primary p-5">
                <h3 className="absolute font-semibold text-white">
                  {initials}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="min-h-screen rounded-sm bg-offwhiteBG p-3 ring-1 ring-neutral-300 sm:h-auto sm:min-h-1/4 sm:p-5">
          <div className="flex min-h-[600px] w-full flex-col md:flex-row md:space-x-4">
            <div className="mb-4 flex md:mb-0">
              <SidebarWidget />
            </div>
            {isNotHome ? (
              <div className="flex min-h-[600px] w-full flex-1 flex-col items-start justify-start gap-3">
                <button
                  className="flex flex-row rounded-sm bg-white px-3 py-2 shadow-lg"
                  onClick={handleBack}
                >
                  <h4 className="flex flex-row items-center gap-2">
                    <FiArrowLeft className="text-black" />
                    Back
                  </h4>
                </button>
                <Outlet />
              </div>
            ) : (
              <Outlet />
            )}
          </div>
        </div>
        <div className="mb-1 mt-10 flex w-full flex-col justify-center text-center font-light text-secondary">
          <p className="text-xs text-gray-600">
            ©{new Date().getUTCFullYear()}. GoESCROW Pty Ltd. ABN 26 650 709 354
            <br />
            All Rights Reserved.
          </p>
          <div className="flex flex-row justify-center gap-4 text-center text-xs text-gray-900">
            <a href="https://goescrow.com.au/tsandcs/">Terms Of Use</a>
            <a href="https://goescrow.com.au/privacy-policy/">Privacy Policy</a>
            <a href="https://goescrow.com.au/">Company Home</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
