import axiosInstance from 'api';
import { API_ENDPOINTS } from 'constants/api';

export const useMapsRequest = () => {
  const getPlaces = async (input: string) => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.MAPS_PLACES_AUTOCOMPLETE,
      {
        params: {
          input: input,
        },
      }
    );
    return { data, status };
  };

  const getPlaceDetails = async (placeId: string) => {
    const { data, status } = await axiosInstance.get(
      API_ENDPOINTS.MAPS_PLACE_DETAILS,
      {
        params: {
          place_id: placeId,
        },
      }
    );
    return { data, status };
  };

  return {
    getPlaceDetails,
    getPlaces,
  };
};
