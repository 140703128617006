import { toast } from 'react-toastify';

export const toastError = (message?: string, interval?: number) => {
  const toastMsg =
    message || 'Error occured. Please try again later or contact support.';
  toast.warn(toastMsg, {
    theme: 'colored',
    position: 'bottom-left',
    autoClose: interval || 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastSuccess = (message?: string, interval?: number) => {
  const toastMsg = message || 'Success!';
  toast.success(toastMsg, {
    theme: 'colored',
    position: 'bottom-left',
    autoClose: interval || 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
