import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useForm } from 'react-hook-form';
import TextInput from 'components/TextInput';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { validatePhoneOrEmail } from 'utils/validations';
import { toastError, toastSuccess } from 'utils/toast';
import FormInput from 'components/FormInput';
import phone from 'phone';

const InviteUser = () => {
  const navigate = useNavigate();
  const { inviteUser } = useUserRequests();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = yup
    .object({
      name: yup.string().required('Their name is required'),
      email_or_phone: yup
        .string()
        .test(
          'is-valid-email-phone',
          'Invalid Email or Phone',
          validatePhoneOrEmail
        )
        .required('Their email is required'),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleInviteUser = useCallback(
    async (data: any) => {
      setIsLoading(true);
      const res = await inviteUser({
        ...data,
        email_or_phone: parseInputIfPhone(data.email_or_phone),
      });
      setIsLoading(false);

      if (res.status) {
        toastSuccess('Invite Sent!');
        navigate(-1);
      } else {
        toastError();
      }
    },
    [navigate]
  );

  const parseInputIfPhone = (input: string) => {
    if (input && input !== '' && input.match(/^[0-9]{8,}$/)) {
      return (
        phone(input, {
          country: 'AUS',
        }).phoneNumber ?? input
      );
    }
    return input;
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 bg-white py-5">
      <h2 className="text-center text-xl font-semibold">Invite User</h2>
      <p className="text-center">
        Invite someone to try the GoEscrow Payment Protection Platform
      </p>

      <div className="flex w-1/2 flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-center text-lg text-primary">
            Their Details
          </label>
          <FormInput
            control={control}
            disabled={isLoading}
            name="name"
            placeholder="Their Name"
            type="text"
          />
          <FormInput
            control={control}
            disabled={isLoading}
            name="email_or_phone"
            placeholder="Their Phone / Email"
            type="text"
          />
        </div>

        <button
          className="btn-main"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(handleInviteUser)}
        >
          Invite
        </button>
      </div>
    </div>
  );
};

export default InviteUser;
