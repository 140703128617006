import React, { useCallback } from 'react';

import watermark from 'assets/images/Watermark.svg';
import sendFunds from 'assets/images/buying.svg';
import requestFunds from 'assets/images/selling.svg';
import { useNavigate } from 'react-router-dom';
import styles from 'utils/styles';

const FundsActionsWidget = () => {
  const navigate = useNavigate();

  const navigateToSend = useCallback(() => {
    navigate('/send-funds');
  }, []);

  const navigateToRequest = useCallback(() => {
    navigate('/request-funds');
  }, []);

  return (
    <div
      className="flex h-[300px] min-w-[300px] flex-col items-stretch justify-center space-y-2 rounded-sm bg-white bg-center bg-no-repeat p-3 pb-6 pt-10 drop-shadow-xl"
      style={{ backgroundImage: `url(${watermark})` }}
    >
      <h3 className="text-center text-2xl font-semibold">Start Escrow</h3>
      <div className="mt-4 flex w-full justify-around">
        <button
          type="button"
          onClick={navigateToSend}
          className="flex min-h-[130px] min-w-[130px] flex-col items-center justify-center rounded-sm bg-gradient-to-b from-primary-80 to-primary p-4 shadow-lg"
        >
          <img src={sendFunds} alt="Send Funds" className={styles.fullImage} />
          <h3 className="mt-3 text-white">I&apos;m buying</h3>
        </button>
        <button
          type="button"
          onClick={navigateToRequest}
          className="flex min-h-[130px] min-w-[130px] flex-col items-center justify-center rounded-sm bg-gradient-to-b from-neutral-700 to-neutral-800 p-4 shadow-lg"
        >
          <img
            src={requestFunds}
            alt="Request Funds"
            className={styles.fullImage}
          />
          <h3 className="mt-3 text-white">I&apos;m selling</h3>
        </button>
      </div>
    </div>
  );
};

export default FundsActionsWidget;
