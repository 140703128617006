/* eslint camelcase: 0 */
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import * as yup from 'yup';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

import { Dropdown } from 'components/Dropdown';
import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';
import { DateTimeFormats } from 'utils/parsers';
import { toastError } from 'utils/toast';
import { useMapsRequest } from '../../hooks/maps-hooks';

type Props = {
  onContinue?: (data: any) => void;
  data?: any;
  readonly?: boolean;
  isUpdate?: boolean;
};

export const AUStates = [
  'New South Wales',
  'Victoria',
  'Queensland',
  'South Australia',
  'Western Australia',
  'Tasmania',
  'Northern Territory',
  'Australian Capital Territory',
];

const BasicDetails: React.FC<Props> = ({
  onContinue,
  data,
  readonly,
  isUpdate = false,
}) => {
  const [addressLine, setAddressLine] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const searchRef = useRef<HTMLInputElement>(null);

  const { getPlaceDetails, getPlaces } = useMapsRequest();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  useEffect(() => {
    const fetchPlaces = async () => {
      if (!debouncedSearchValue) {
        setSuggestions([]);
        return;
      }

      try {
        const res = await getPlaces(debouncedSearchValue);

        const { predictions } = res.data;

        if (predictions.length) {
          const newSuggestions = predictions.map((prediction: any) => {
            return {
              text: prediction.description,
              placeId: prediction.place_id,
            };
          });

          setSuggestions([...newSuggestions]);
        } else {
          setSuggestions([]);
        }
      } catch (err) {
        setSuggestions([]);
      }
    };

    fetchPlaces();
  }, [debouncedSearchValue]);

  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
  };

  const onSelectOption = useCallback((value: string) => {
    setValue('state', value, { shouldValidate: true });
  }, []);

  const schema = yup
    .object({
      first_name: yup.string().required('First Name required'),
      last_name: yup.string().required('Last Name required'),
      email: yup.string().email().required('Email required'),
      street_number: yup.string().required('Street number is required'),
      street: yup.string().required('Street'),
      suburb: yup.string().required('Suburb required'),
      postcode: yup.string().required('Postcode required'),
      birth_date: yup.date().required('Birth Date required'),
      state: yup.string().oneOf(AUStates).defined().required('State required'),
    })
    .required();

  if (data && data.birth_date && data.birth_date !== null) {
    data.birth_date = new Date(data.birth_date);
  }

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    ...(data ? { defaultValues: data } : {}),
  });

  const birthDateValue = watch('birth_date');
  const stateValue = watch('state');

  const dataAddressLine = useMemo(
    () => (data && data.street ? `${data.street_number} ${data.street}` : ''),
    [data]
  );

  const handleSearchBlur = () => {
    setTimeout(() => {
      setSuggestions([]);
    }, 250);
  };

  const SuggestionPopup = useMemo(() => {
    return (
      <div className="suggestion-popup">
        <ul>
          {suggestions.map((suggestion: any, index) => (
            <li onClick={() => handleSelectAddress(suggestion)} key={index}>
              {suggestion.text}
            </li>
          ))}
        </ul>
      </div>
    );
  }, [suggestions]);

  useEffect(() => {
    if (isUpdate && data) {
      setAddressLine(`${data.street_number} ${data.street}`);
    }
    reset(data);
  }, []);

  const handleSelectAddress = useCallback(
    async (address: any) => {
      try {
        const res = await getPlaceDetails(address.placeId);

        res.data.result.address_components.forEach((component: any) => {
          const { types, long_name } = component;
          if (types.includes('street_number')) {
            setValue('street_number', long_name, { shouldValidate: true });
          }
          if (types.includes('route')) {
            setValue('street', long_name, { shouldValidate: true });
          }
          if (types.includes('locality')) {
            setValue('suburb', long_name, { shouldValidate: true });
          }
          if (types.includes('postal_code')) {
            setValue('postcode', long_name, { shouldValidate: true });
          }
          if (types.includes('administrative_area_level_1')) {
            setValue('state', long_name, { shouldValidate: true });
          }
        });

        (searchRef.current! as any).value = address.text;
        setAddressLine(address.text);
      } catch (err) {
        toastError();
      }

      setSuggestions([]);
    },
    [control]
  );

  const shouldShowAddressFields = useMemo(
    () => readonly || isUpdate || addressLine !== '',
    [isUpdate, readonly, addressLine]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      if (!onContinue || !formData || !isValid) return;

      onContinue({
        address: {
          ...(formData ? formData : data),
          country: 'Australia',
        },
        basicDetails: {
          first_name: formData.first_name || data.first_name,
          last_name: formData.last_name || data.last_name,
          phone: data.phone,
          birth_date: format(birthDateValue, DateTimeFormats.FormDate),
        },
      });
    },
    [birthDateValue, data, isValid, onContinue]
  );

  const handleDateChange = useCallback((date: Date) => {
    setValue('birth_date', date, { shouldValidate: true });
  }, []);

  return (
    <form className="my-3 w-full" onSubmit={handleSubmit(handleContinue)}>
      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled={readonly || !isUpdate}
            name="first_name"
            label="First Name"
            type="text"
            autoComplete="fname"
            placeholder="Enter First Name"
          />
          <FormInput
            control={control}
            disabled={readonly || !isUpdate}
            name="last_name"
            label="Last Name"
            type="text"
            autoComplete="lname"
            placeholder="Enter Last Name"
          />

          <div>
            <label className="pl-1 text-sm text-secondary">Date of Birth</label>
            <ReactDatePicker
              selected={birthDateValue}
              onChange={handleDateChange}
              disabled={readonly}
              dateFormat={DateTimeFormats.DisplayDateShort}
              dateFormatCalendar="MMMM"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
            />
          </div>
        </div>
        <div className="flex-1 flex-col space-y-3">
          {isUpdate && (
            <FormInput
              control={control}
              disabled
              name="reference"
              label="GoEscrow ID"
              placeholder="GoEscrow ID"
            />
          )}
          <FormInput
            control={control}
            disabled
            name="email"
            type="email"
            label="Email Address"
            autoComplete="email"
            placeholder="Enter Email Address"
          />
          <FormInput
            control={control}
            disabled
            name="phone"
            label="Phone Number"
            placeholder="Phone Number"
          />
        </div>
      </div>

      <p className="mt-6 font-semibold text-primary">Address Details</p>

      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="flex-1 flex-col space-y-3">
          {!readonly && (
            <div className="text-field-with-suggestion">
              <label className="pl-1 text-sm text-secondary">
                Address Search
              </label>
              <input
                ref={searchRef}
                disabled={readonly}
                onBlur={handleSearchBlur}
                onChange={handleSearchChange}
                placeholder={dataAddressLine || 'Search'}
                className="relative block min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 
            text-sm font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
              />
              {suggestions.length > 0 && SuggestionPopup}
            </div>
          )}
          {shouldShowAddressFields ? (
            <>
              <FormInput
                control={control}
                disabled={readonly}
                name="street_number"
                label="Street Number"
                type="text"
                placeholder={readonly ? '' : '10'}
              />
              <FormInput
                control={control}
                disabled={readonly}
                name="street"
                label="Street"
                type="text"
                placeholder={readonly ? '' : 'Matlet Street'}
              />

              <FormInput
                control={control}
                disabled={readonly}
                name="unit_number"
                label="Unit Number (optional)"
                type="text"
                placeholder={readonly ? '' : '3B'}
              />
            </>
          ) : null}
        </div>

        <div className="flex-1 flex-col space-y-3">
          {shouldShowAddressFields ? (
            <>
              <FormInput
                control={control}
                disabled={readonly}
                name="suburb"
                label="Town/Suburb"
                type="text"
                placeholder={readonly ? '' : 'Nedlands'}
              />
              <div>
                <label className="pl-1 text-sm text-secondary">
                  Select Region, State or District
                </label>
                <Dropdown
                  items={AUStates}
                  disabled={readonly}
                  selected={stateValue}
                  onChange={onSelectOption}
                />
              </div>
              <FormInput
                control={control}
                disabled={readonly}
                name="postcode"
                label="Postcode"
                type="text"
                placeholder={readonly ? '' : '4301'}
              />
            </>
          ) : null}
        </div>
      </div>
      {!readonly ? (
        <div className="mt-6 w-full sm:w-1/2 sm:pr-2">
          <button
            disabled={!isValid}
            className="btn-main w-full self-center py-2 sm:self-start"
          >
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default BasicDetails;
