import { useQuery } from '@tanstack/react-query';
import ActiveDealsWidget from 'components/widgets/ActiveDealsWidget';
import FundsActionsWidget from 'components/widgets/FundsActionsWidget';
import SpecialOfferWidget from 'components/widgets/SpecialOfferWidget';
import { useUserRequests } from 'hooks/user-hooks';
import { useEffect, useMemo } from 'react';
import { checkSufficientIdentification } from 'utils/helpers';

const Dashboard = () => {
  const { getUserDetails, doLoginNavigations } = useUserRequests();

  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : undefined),
    [userData]
  );

  console.log('[Log] userDetails', { userData });

  useEffect(() => {
    if (
      !userDetails ||
      !userDetails.mobile_verified
      // || !(
      //   userDetails.birth_date &&
      //   userDetails.address &&
      //   checkSufficientIdentification(userDetails)
      // )
    ) {
      doLoginNavigations();
    }
  }, []);

  return (
    <div className="flex w-full flex-col md:flex-row md:space-x-4">
      <div className="mb-4 flex flex-1 flex-col items-stretch space-y-4 md:mb-0">
        <FundsActionsWidget />
        <ActiveDealsWidget
          title="Active Transactions"
          status={
            'pending,accepted,cancel-requested,in-dispute,contact-helpdesk,settlement-requested'
          }
        />
      </div>
      <div className="flex flex-1 flex-col  items-stretch space-y-4">
        <ActiveDealsWidget
          title="Recent Transactions"
          status={'cancelled,completed,declined'}
        />
        <SpecialOfferWidget />
      </div>
    </div>
  );
};

export default Dashboard;
