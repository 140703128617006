import { Listbox } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { FiCheck, FiDownload, FiFilter } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { DateTimeFormats } from 'utils/parsers';

const selectItems = [
  'Pending',
  'Accepted',
  'Settlement-Requested',
  'Completed',
  'Declined',
  'Cancel-Requested',
  'Cancelled',
  'In-Dispute',
  'Contact-Helpdesk',
];

const Transactions = () => {
  const { getDeals, dealNavigationHelper, exportTransactionsToCSV } =
    useDealsRequests();
  const { getAuth } = useAmplifyAuth();
  const { getUserDetails } = useUserRequests();
  const [selectValue, setSelectedValue] = useState<string[]>(selectItems);

  const { data: dealsRes, isLoading } = useQuery(
    ['dealsList', selectValue],
    () =>
      getDeals({
        per_page: 100,
        ...(selectValue && selectValue.length
          ? { status: selectValue.toString().toLowerCase() }
          : {}),
      })
  );
  const { data: userRes } = useQuery(['userDetails'], getUserDetails);

  const dealsData = useMemo(
    () => (dealsRes && dealsRes.status ? dealsRes?.data : []),
    [dealsRes]
  );
  const userDetails = useMemo(
    () => (userRes && userRes.status ? userRes?.data : {}),
    [userRes]
  );
  const selectDisplay = useMemo(() => {
    if (!selectValue || selectValue.length <= 0) {
      return 'Filter...';
    }
    if (selectValue.length >= selectItems.length) return 'All';
    return selectValue.toString().replace(',', ', ');
  }, [selectValue]);

  const onSelectOption = useCallback(
    (value: any[]) => {
      setSelectedValue(value);
    },
    [setSelectedValue]
  );

  const handleViewDeal = useCallback(
    (deal: any) => () => {
      dealNavigationHelper(deal, userDetails, false);
    },
    [userDetails]
  );

  const handleExportCSV = useCallback(async () => {
    const token = await getAuth();
    const res = await exportTransactionsToCSV(token || '');

    if (res && res.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([res.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'customer-audit-export.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }, []);

  const renderItem = (item: any, index: any) => {
    let transactorType = 'Buyer';

    if (item.initiator && item.initiator.toLowerCase() === 'me') {
      transactorType = item.transaction_type === 'send' ? 'Buyer' : 'Seller';
    } else {
      transactorType = item.transaction_type === 'send' ? 'Seller' : 'Buyer';
    }

    return (
      <button
        key={item.reference}
        className="flex w-full flex-row justify-between rounded-sm bg-white p-3 drop-shadow-lg"
        type="button"
        onClick={handleViewDeal(item)}
      >
        <div className="flex flex-col items-start">
          <p>
            {item?.reference} - {transactorType}
          </p>
          <p className="text-sm text-primary">
            ${parseFloat(item?.amount)} -{' '}
            {(item?.payment_status === 'paid'
              ? 'IN ESCROW'
              : item?.payment_status ?? ''
            ).toUpperCase()}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-sm">
            {format(
              new Date(item?.created_at),
              DateTimeFormats.DisplayDateTimeShort
            )}
          </p>
          <p className="text-sm text-primary">
            {item?.public_status?.toUpperCase()}
          </p>
        </div>
      </button>
    );
  };

  return (
    <div className="flex w-full flex-col gap-4 bg-white p-5">
      <div className="flex flex-col items-center sm:flex-row sm:justify-between">
        <h2 className="text-xl font-semibold">Transactions</h2>

        <div className="w-full sm:w-auto sm:flex-none">
          <Listbox
            as="div"
            className="relative flex-1 sm:flex-none"
            value={selectValue}
            onChange={onSelectOption}
            multiple
          >
            <Listbox.Button className="mt-2 w-full rounded-sm border border-gray-300 bg-white py-2 px-4 text-left font-light text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100 sm:mt-0 sm:w-72">
              <div className="flex flex-row items-center justify-between text-ellipsis">
                <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {selectDisplay}
                </p>
                <FiFilter className="text-neutral-400" />
              </div>
            </Listbox.Button>
            <Listbox.Options
              className={
                'absolute right-0 z-10 mt-2 w-full origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-gray-200 focus:outline-none'
              }
            >
              {selectItems.map((item) => (
                <Listbox.Option key={item} value={item}>
                  {({ active, selected }) => (
                    <li
                      className={twMerge(
                        selected && 'font-normal text-gray-900',
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex flex-row justify-between px-4 py-2 text-base font-light '
                      )}
                    >
                      {item}
                      {selected && <FiCheck className="text-xl text-primary" />}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      </div>

      <div className="mt-4 max-h-[500px] overflow-y-auto px-2 py-1">
        {!dealsData || dealsData.length <= 0 || isLoading ? (
          <div className="flex w-full flex-1 items-center justify-center">
            <p>No tansactions available.</p>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              {dealsData.map(renderItem)}
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleExportCSV}
                className="mt-5 flex flex-row justify-center gap-2 p-2 align-middle text-sm font-semibold text-primary"
              >
                <FiDownload className="text-lg" />
                Customer Audit Download
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Transactions;
