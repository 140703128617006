import _ from 'lodash';

export const checkSufficientIdentification = (user: any) => {
  if (!user) return false;

  const validIds = [
    _.has(user, ['driver_license', 'license_number']),
    _.has(user, ['medicare', 'document_number']),
    _.has(user, ['passport', 'document_number']),
  ];

  return validIds.filter((item) => item === true).length >= 2;
};
