import { Dialog } from '@headlessui/react';
import Modal from 'components/Modal';
import { USER_DETAILS_TYPES } from 'constants/enums';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useCallback, useState } from 'react';
import { FiArrowRight, FiMail, FiPhone } from 'react-icons/fi';
import { IoKeyOutline } from 'react-icons/io5';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';

import AccountIcon from 'assets/icons/account.svg';
import BankIcon from 'assets/icons/bank.svg';
import HelpdeskIcon from 'assets/icons/helpdesk.svg';
import IdentificationIcon from 'assets/icons/identification.svg';
import InviteIcon from 'assets/icons/invite.svg';
import LogoutIcon from 'assets/icons/logout.svg';
import SettingsIcon from 'assets/icons/settings.svg';
import TransactionsIcon from 'assets/icons/transactions.svg';

const SidebarWidget = () => {
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const { signOut } = useAmplifyAuth();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    const res = await signOut();
    if (res) {
      navigate(ROUTES.LOGIN);
    } else {
      toastError();
    }
  }, [signOut, navigate]);

  const handleChange = useCallback(
    (mode: 'mobile' | 'email' | 'password') => () => {
      setShowSettingsModal(false);
      switch (mode) {
        case 'email':
          navigate(ROUTES.CHANGE_EMAIL);
          break;
        case 'mobile':
          navigate(ROUTES.CHANGE_MOBILE);
          break;
        case 'password':
          navigate(ROUTES.CHANGE_PASSWORD);
          break;
      }
    },
    [navigate]
  );

  const sidebarItems = [
    // {
    //   icon: TbLayoutGridAdd,
    //   action: () => {
    //     /* do something */
    //   },
    // },
    // {
    //   icon: HiArrowsRightLeft,
    //   action: () => {
    //      do something
    //   },
    // },
    {
      icon: TransactionsIcon,
      action: () => {
        navigate(ROUTES.TRANSACTIONS);
      },
    },
    // {
    //   icon: FiUserPlus,
    //   action: () => {
    //     /* do something */
    //   },
    // },
    {
      icon: AccountIcon,
      action: () => {
        navigate({
          pathname: ROUTES.VIEW_DETAILS,
          search: `?${createSearchParams({
            type: USER_DETAILS_TYPES.ACCOUNT,
            mode: 'view',
          })}`,
        });
      },
    },
    {
      icon: BankIcon,
      action: () => {
        navigate({
          pathname: ROUTES.VIEW_DETAILS,
          search: `?${createSearchParams({
            type: USER_DETAILS_TYPES.BANK,
            mode: 'view',
          })}`,
        });
      },
    },
    {
      icon: IdentificationIcon,
      action: () => {
        navigate({
          pathname: ROUTES.VIEW_DETAILS,
          search: `?${createSearchParams({
            type: USER_DETAILS_TYPES.IDENTIFICATION,
            mode: 'view',
          })}`,
        });
      },
    },
    {
      icon: InviteIcon,
      action: () => {
        navigate(ROUTES.INVITE_USER);
      },
    },
    // {
    //   icon: FiStar,
    //   action: () => {
    //     /* do something */
    //   },
    // },
    {
      icon: HelpdeskIcon,
      action: () => {
        setShowHelpModal(true);
      },
    },

    {
      icon: SettingsIcon,
      action: () => {
        setShowSettingsModal(true);
      },
    },
  ];

  return (
    <div className="flex w-full flex-row items-center justify-between rounded-sm bg-white shadow-lg shadow-neutral-300 md:min-w-[48px] md:flex-col">
      <div className="flex flex-row items-center justify-center space-y-1 px-1 py-1.5 md:flex-col md:py-1">
        {sidebarItems.map((item, index) => (
          <button
            key={index}
            className="border-r-2 border-gray-300 p-2 px-2.5 md:border-b-2 md:border-r-0 md:px-2"
            onClick={item.action}
          >
            <img src={item.icon} className="py-1 text-2xl text-secondary" />
          </button>
        ))}
      </div>
      <button className="p-2.5" onClick={handleLogout}>
        <img src={LogoutIcon} className="p-1 text-2xl text-secondary" />
      </button>
      <Modal isOpen={showHelpModal} onClose={() => setShowHelpModal(false)}>
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-2xl font-medium leading-6 text-secondary"
        >
          Helpdesk
        </Dialog.Title>
        <div className="flex flex-col gap-3 p-5">
          <a
            href="https://www.goescrow.com.au/helpdesk-app"
            rel="noreferrer"
            target="_blank"
          >
            <div className="rounded-sm bg-white px-6 py-4 drop-shadow-xl">
              <div className="flex flex-row justify-between">
                <h3 className="text-xl text-primary">General Helpdesk</h3>
                <FiArrowRight className="text-2xl text-primary" />
              </div>
              <p className="mt-4">
                For general assistance, app feedback, friendly contact and
                commercial sales assistance.
              </p>
            </div>
          </a>
          <a
            href="https://www.goescrow.com.au/disputedesk-app"
            rel="noreferrer"
            target="_blank"
          >
            <div className="rounded-sm bg-white px-6 py-4 drop-shadow-xl">
              <div className="flex flex-row justify-between">
                <h3 className="text-xl text-primary">Transaction Disputes</h3>
                <FiArrowRight className="text-2xl text-primary" />
              </div>
              <p className="mt-4">
                For assistance with specific transaction disputes.
              </p>
            </div>
          </a>
        </div>
      </Modal>
      <Modal
        isOpen={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      >
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-2xl font-medium leading-6 text-secondary"
        >
          Contact Settings
        </Dialog.Title>
        <div className="flex flex-col gap-3 p-5">
          <h2 className="font-semibold text-primary">Your Contacts</h2>
          <p className="mb-3 text-sm text-neutral-500">
            Please let us know how to contact you. Note the email and mobile
            can&apos;t be in use by another GoESCROW account.
          </p>
          <button
            className="flex flex-row items-center justify-between rounded-sm bg-white px-6 py-4 drop-shadow-xl"
            onClick={handleChange('email')}
          >
            <FiMail className="text-xl text-primary" />
            <h3 className="">Change Email Address</h3>
            <FiArrowRight className="text-xl text-primary" />
          </button>
          <button
            className="flex flex-row items-center justify-between rounded-sm bg-white px-6 py-4 drop-shadow-xl"
            onClick={handleChange('mobile')}
          >
            <FiPhone className="text-xl text-primary" />
            <h3>Change Mobile Number</h3>
            <FiArrowRight className="text-xl text-primary" />
          </button>
          <button
            className="flex flex-row items-center justify-between rounded-sm bg-white px-6 py-4 drop-shadow-xl"
            onClick={handleChange('password')}
          >
            <IoKeyOutline className="text-2xl text-primary" />
            <h3>Change Password</h3>
            <FiArrowRight className="text-xl text-primary" />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SidebarWidget;
