export const parseBsbNumber = (bsb: string) => {
  if (!bsb || bsb.length <= 0 || bsb.includes('-')) return bsb;
  return `${bsb.substring(0, 3)}-${bsb.substring(3)}`;
};

export const getinitials = (fullname: string) => {
  if (!fullname) return '';

  const names = fullname?.toString().split(' ');
  if (names.length === 1) {
    return names[0][0].toUpperCase();
  } else {
    return (names[0][0] + names[1][0]).toUpperCase();
  }
};

export const DateTimeFormats = {
  DisplayDateShort: 'MMMM d, yyyy',
  DisplayMonth: 'MMMM yyyy',
  DisplayDateTimeShort: 'dd/MM/yyyy p',
  DisplayDateTimeLong: 'E, d MMM yyyy p',
  FormDate: 'yyyy-MM-dd',
  FormDateTime: 'yyyy-MM-dd HH:mm:ss',
};
