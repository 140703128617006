const styles = {
  boxWidth: 'xl:max-w-[1280px] w-full',

  paragraph: 'font-normal text- text-[18px] leading-[30.8px]',

  fullImage: 'flex h-auto w-auto flex-1',

  fill: 'w-full h-full',
  flexCenter: 'flex justify-center items-center',
  flexStart: 'flex justify-center items-start',

  paddingX: 'sm:px-16 px-6',
  paddingY: 'sm:py-16 py-6',
  padding: 'sm:px-16 px-6 sm:py-12 py-4',

  marginX: 'sm:mx-16 mx-6',
  marginY: 'sm:my-16 my-6',
};

export default styles;
