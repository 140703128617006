// TODO: Export these to env
export const API_BASE_URL = 'https://api.goescrow.net/api';
export const GOOGLE_API_KEY = 'AIzaSyC-exG2QAB3slR19vwudqNbqPfnmEtoqTI';

export const API_ENDPOINTS = {
  // User
  USER_CREATE: 'users/monoova',
  USER_DETAILS: 'users/me',
  USER_BASIC_DETAILS: 'users/details',
  USER_ADDRESS: 'users/address',
  USER_IDENTITY: 'users/identification',
  USER_BANK_DETAILS: 'users/bank',
  USER_VERIFY_BANK: 'users/bank-verify',
  USER_PAYMENT: 'users/paymentDetails',
  USER_LOOKUP: 'public/lookup',
  USER_INVITE: 'users/invite',
  USER_EMAIL_EXISTS: 'users/email-exists',
  USER_UPDATE_EMAIL: 'users/email',
  USER_UPDATE_PHONE: 'users/phone',
  USER_VERIFY_PHONE: 'users/phone-verify',
  USER_FORGOT_PASSWORD: 'users/forgot-password',

  DRIVER_LICENSE: 'users/identification/driverlicense',
  MEDICARE: 'users/identification/medicare',
  PASSPORT: 'users/identification/passport',

  MAPS_PLACES_AUTOCOMPLETE: 'places/autocomplete',
  MAPS_PLACE_DETAILS: 'place/details',

  DEALS: 'deals',
  DEALS_FEES: 'deals/fees',
  DEALS_TOKEN: 'deals/token',
  EXPORT_CSV: 'action-audits/downloads/csv',
};
