import { Dialog, Popover } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Modal from 'components/Modal';
import NotesSection from 'components/NotesSection';
import TextInput from 'components/TextInput';
import {
  PaymentStatusExplainers,
  TransactionStatusExplainers,
} from 'constants/strings';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { ROUTES } from 'routers/routes';
import { DateTimeFormats } from 'utils/parsers';
import { toastError, toastSuccess } from 'utils/toast';

const TransactionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isTickedCheckbox, setCheckboxTick] = useState<boolean>(false);
  const [isDealClosedModalShown, setDealClosedModalShown] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isReleaseFundsModalShown, setReleaseFundsModalShown] =
    useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  const [isCancellationRequestModalShown, setCancellationRequestModalShown] =
    useState<boolean>(false);
  const [isAcceptCancellationModalShown, setCancellationModalShown] =
    useState<boolean>(false);

  const [confirmationModalState, setConfirmationModalState] = useState<{
    isOpen: boolean;
    title?: string;
    message?: string;
    onConfirm?: () => void;
  }>({
    isOpen: false,
  });

  const transactionId = searchParams.get('transactionId') || undefined;
  const poliPaymentRedirectStatus = searchParams.get('payment') || undefined;

  const dealId = useMemo(
    () => transactionId || location?.state?.dealId || undefined,
    [location.state, transactionId, poliPaymentRedirectStatus]
  );

  console.log('[Log] dealId', { dealId });

  const { getDealDetails, closeDeal, cancelDeal, denyCancelRequest } =
    useDealsRequests();
  const { getUserDetails } = useUserRequests();

  const {
    data: dealData,
    refetch,
    isLoading,
  } = useQuery(['dealDetails', dealId], () => getDealDetails(dealId));

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    ['userDetails'],
    getUserDetails,
    {
      refetchInterval: false,
    }
  );

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );

  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const otherPartyReference = useMemo(() => {
    if (!(dealDetails.user && userDetails.id)) return '';
    const { user } = dealDetails;
    const { id } = userDetails;

    return user.id === id ? dealDetails.user_reference : user.email;
  }, [userDetails, dealDetails]);

  useEffect(() => {
    if (
      dealDetails?.status === 'cancel-requested' &&
      isBuyer &&
      !isCancellationRequestor
    ) {
      setConfirmationModalState({
        isOpen: true,
        title: 'NOTICE CANCELLATION REQUESTED',
        message:
          'Your seller has requested that you cancel this transaction and that GoEscrow refund the monies held in escrow back to you minus fees.  Please confirm this request if you have not received any goods or services requiring payment to the seller from any funds in escrow and are happy to receive back any funds you have placed in escrow. If you accept GoEscrow will reverse your payment.  If unsure select the wait button and communicate with the seller.  If you deny their request then select the deny button and everything will revert to an active transaction.',
      });
    }
  }, [isLoading]);

  // -------------------------------- useCallbacks

  const handleWait = useCallback(() => {
    navigate(ROUTES.HOME);
  }, []);

  const handleTransferPayment = useCallback(() => {
    navigate(ROUTES.TRANSFER_PAYMENT, { state: { dealId: dealDetails.id } });
  }, [navigate, dealDetails]);

  const handleCloseDealModal = useCallback(() => {
    setDealClosedModalShown(false);
  }, []);

  const handleCloseReleaseFundsModal = useCallback(() => {
    setReleaseFundsModalShown(false);
  }, []);

  const handleCloseDeal = useCallback(async () => {
    setIsSubmitting(true);
    setInvalidCode(false);
    setCode('');
    const res = await closeDeal(dealId, code);

    if (res?.data?.two_factor_required) {
      setIsSubmitting(false);
      setTimeout(() => {
        setReleaseFundsModalShown(true);
      }, 1000);
      return;
    }

    if (res?.data?.two_factor_invalid) {
      setIsSubmitting(false);
      setInvalidCode(true);
      return;
    }

    if (res.status) {
      setIsSubmitting(false);

      setTimeout(() => {
        setReleaseFundsModalShown(false);
        refetch();

        setTimeout(() => {
          setDealClosedModalShown(true);
        }, 750);
      }, 1000);
    } else {
      setIsSubmitting(false);
      toastError();
    }
  }, [dealId, code]);

  // -------------------------------- useEffect
  useEffect(() => {
    if (transactionId && poliPaymentRedirectStatus) {
      switch (poliPaymentRedirectStatus) {
        case 'success': {
          toastSuccess();
          break;
        }
        case 'fail': {
          handleTransferPayment();
          toastError(
            'Error occurred: Pay by Poli failed. Please try again later.'
          );
          break;
        }
        case 'cancel': {
          handleTransferPayment();
          break;
        }
      }
    }
  }, []);

  // -------------------------------- useMemo

  const dealAccepted = useMemo(
    () => dealDetails?.status === 'accepted',
    [dealDetails]
  );
  const hasToInvite = useMemo(
    () => dealDetails && !dealDetails?.other_user && !dealDetails?.match,
    [dealDetails]
  );

  const isCloseDealDisabled = useMemo(
    () => !isTickedCheckbox || !dealAccepted,
    [isTickedCheckbox, dealAccepted]
  );

  const isInitiator = useMemo(
    () => dealDetails && dealDetails.initiator === 'me',
    [dealDetails]
  );

  const isCancellationRequestor = useMemo(
    () =>
      dealDetails && dealDetails.cancel_requestor_user_id === userDetails?.id,
    [dealDetails]
  );

  const dealNotes = useMemo(
    () => (dealDetails && dealDetails.notes ? dealDetails.notes : []),
    [dealDetails]
  );

  const releaseDate = useMemo(
    () =>
      dealDetails && dealDetails.type === 'time' && dealDetails.release_at
        ? format(
            new Date(dealDetails.release_at),
            DateTimeFormats.DisplayDateTimeLong
          )
        : null,
    [dealDetails]
  );

  const isEarlySettle = useMemo(
    () =>
      dealDetails &&
      dealDetails.type === 'time' &&
      dealDetails.status == 'completed' &&
      dealDetails.release_at &&
      !(new Date(dealDetails.release_at) < new Date(dealDetails.updated_at)),
    [dealDetails, releaseDate]
  );

  const dealType = useMemo(() => {
    if (!dealDetails || !dealDetails.type) return '';
    return dealDetails.type === 'anytime' ? 'Anytime' : 'Timed Escrow';
  }, [dealDetails]);

  const isBuyer = useMemo(
    () =>
      (isInitiator && dealDetails?.transaction_type === 'send') ||
      (!isInitiator && dealDetails?.transaction_type === 'request'),
    [dealDetails, isInitiator]
  );

  const settlementAccountDetails = useMemo(
    () =>
      dealDetails?.other_user?.bank_account || dealDetails?.user?.bank_account,
    [dealDetails]
  );

  const isActiveTransaction = useMemo(
    () =>
      dealDetails &&
      dealDetails.status &&
      ![
        'cancelled',
        'declined',
        'cancel-requested',
        'settled',
        'settlement-requested',
      ].includes(dealDetails.status),
    [dealDetails]
  );

  const showSellerRedlight = useMemo(
    () =>
      isActiveTransaction &&
      !isBuyer &&
      dealDetails &&
      (dealDetails.payment_status === 'pending' ||
        dealDetails.payment_status === 'partial'),
    [isBuyer, dealDetails]
  );

  const showSellerGreenlight = useMemo(
    () =>
      isActiveTransaction &&
      !isBuyer &&
      dealDetails &&
      dealDetails.payment_status === 'paid',
    [isBuyer, dealDetails]
  );

  const showSettlementPending = useMemo(
    () =>
      dealDetails &&
      dealDetails.status &&
      dealDetails.status === 'settlement-requested',
    [dealDetails]
  );

  const isPromptedToPay = useMemo(
    () =>
      isBuyer &&
      (dealDetails.payment_status === 'pending' ||
        dealDetails.payment_status === 'partial'),
    [isBuyer, dealDetails]
  );

  const showInviteCard = useMemo(
    () => isInitiator && hasToInvite && dealDetails?.status === 'pending',
    [hasToInvite, isInitiator, dealDetails]
  );

  const showAwaitingDealAcceptance = useMemo(
    () =>
      dealDetails &&
      dealDetails.initiator === 'me' &&
      dealDetails.status === 'pending',
    [dealDetails]
  );

  const showCloseDeal = useMemo(
    () =>
      ((isInitiator && dealDetails?.transaction_type === 'send') ||
        (!isInitiator && dealDetails?.transaction_type === 'request')) &&
      dealDetails?.status === 'accepted' &&
      dealDetails?.payment_status === 'paid',
    [isInitiator, dealDetails]
  );

  const TimedEscrowDisclaimers = useMemo(
    () => (
      <p className="mt-4 flex flex-col text-center italic">
        {dealDetails?.status === 'completed' &&
          isEarlySettle &&
          `${isBuyer ? 'You' : 'The buyer'} settled early`}
        {dealDetails?.status === 'completed' && !isEarlySettle && (
          <div className="flex flex-row">
            This transaction was automatically settled on
            <span className="ml-1 text-primary">{releaseDate}.</span>
          </div>
        )}
        {dealDetails?.status !== 'completed' && (
          <div className="flex flex-row">
            This transaction will automatically be settled on
            <span className="ml-1 text-primary">{releaseDate}.</span>
          </div>
        )}
        {!isBuyer &&
          ['accepted', 'pending'].includes(dealDetails?.status) &&
          ' The buyer may settle early.'}
      </p>
    ),
    [dealDetails, isBuyer]
  );

  const transactionStatusExplainer = useMemo(() => {
    if (['pending', 'accepted', 'declined'].includes(dealDetails.status)) {
      return TransactionStatusExplainers[
        `${dealDetails.status}-${isBuyer ? 'buyer' : 'seller'}`
      ];
    }
    return TransactionStatusExplainers[dealDetails.status] || '';
  }, [dealDetails.status, isBuyer]);

  const paymentStatusExplainer = useMemo(() => {
    if (['pending', 'partial'].includes(dealDetails.payment_status)) {
      return PaymentStatusExplainers[
        `${dealDetails.payment_status}-${isBuyer ? 'buyer' : 'seller'}`
      ];
    }
    return PaymentStatusExplainers[dealDetails.payment_status] || '';
  }, [dealDetails.payment_status, isBuyer]);

  const showCancelRequest = useMemo(() => {
    return dealDetails?.status === 'accepted';
  }, [dealDetails, isBuyer]);

  const showCancelRequestControls = useMemo(() => {
    return (
      !isLoadingUser &&
      dealDetails?.status === 'cancel-requested' &&
      !isCancellationRequestor
    );
  }, [dealDetails, isCancellationRequestor, isLoadingUser]);

  const ConfirmationModal = useMemo(() => {
    const { isOpen, title, message, onConfirm } = confirmationModalState;
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => setConfirmationModalState({ isOpen: false })}
        >
          {title && (
            <>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-primary"
              >
                {title}
              </Dialog.Title>
            </>
          )}

          {message && (
            <>
              <div className="mt-4">{message}</div>
            </>
          )}

          <div className="mt-8 flex flex-row-reverse items-end gap-4">
            <button
              onClick={() => {
                onConfirm?.();
                setConfirmationModalState({ isOpen: false });
              }}
              className="btn-main"
            >
              {onConfirm ? 'Yes' : 'Ok'}
            </button>

            {onConfirm && (
              <>
                <button
                  onClick={() => setConfirmationModalState({ isOpen: false })}
                  className="rounded-lg bg-secondary py-3 px-4 font-bold text-white hover:bg-secondary-120"
                >
                  No
                </button>
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }, [confirmationModalState]);

  // -------------------------------- use callBacks 2

  const toggleCheckbox = useCallback(() => {
    setCheckboxTick(!isTickedCheckbox);
  }, [isTickedCheckbox]);

  const handleDenyCancellationRequest = useCallback(() => {
    setConfirmationModalState({
      isOpen: true,
      title: 'Deny Cancellation Request',
      message: 'Are you sure you want to deny this request?',
      onConfirm: async () => {
        const res = await denyCancelRequest(dealId);
        if (res.status) {
          refetch();
        } else {
          toastError();
        }
      },
    });
  }, [dealId]);

  const handleAcceptCancellation = useCallback(async () => {
    setIsSubmitting(true);
    setInvalidCode(false);

    setCode('');

    const res = await cancelDeal(dealId, code);

    if (res?.data?.two_factor_required) {
      setIsSubmitting(false);
      setTimeout(() => {
        setCancellationModalShown(true);
      }, 1000);
      return;
    }

    if (res?.data?.two_factor_invalid) {
      setIsSubmitting(false);
      setInvalidCode(true);
      toastError('Invalid Code');
      return;
    }

    if (res.status) {
      setIsSubmitting(false);

      setTimeout(() => {
        setCancellationModalShown(false);
        refetch();
      }, 1000);
    } else {
      setIsSubmitting(false);
      toastError();
    }
  }, [code, dealId]);

  const handleCancelPendingDeal = useCallback(async () => {
    setConfirmationModalState({
      isOpen: true,
      title: 'Cancel',
      message: 'Are you sure you want to cancel this transaction?',
      onConfirm: async () => {
        setIsSubmitting(true);

        const res = await cancelDeal(dealId, code);

        if (res.data) {
          refetch();
          toastSuccess();
          setIsSubmitting(false);
        } else {
          toastError('Something went wrong while processing your request');
          setIsSubmitting(false);
        }
      },
    });
  }, [dealId, code]);

  const handleCancelDeal = useCallback(async () => {
    let alertMessage =
      'Request cancellation for this transaction and for the seller to release back to you any funds held in escrow. ' +
      'You confirm that you have not received any goods or services that require payment from escrow to the seller. ' +
      'You confirm that any levied fees by GoESCROW for escrow management services are not returned';
    const alertTitle = 'Request Cancellation';

    if (!isBuyer) {
      alertMessage =
        'Request cancellation for this transaction and for the buyer to acknowledge that you are not shipping any goods or services ' +
        'and wish for any funds in escrow to be returned to them minus the GoESCROW fee for escrow payment management services';
    }

    if (isCancellationRequestModalShown) {
      setInvalidCode(false);
      setIsSubmitting(true);
      setCode('');

      const res = await cancelDeal(dealId, code);

      if (res?.data?.two_factor_invalid) {
        setIsSubmitting(false);
        setInvalidCode(true);
        toastError('Invalid Code');
        return;
      }

      if (res.status) {
        setIsSubmitting(false);

        setTimeout(() => {
          setCancellationRequestModalShown(false);
          refetch();
        }, 500);
      } else {
        setIsSubmitting(false);
        toastError();
      }

      return;
    }

    setConfirmationModalState({
      isOpen: true,
      title: alertTitle,
      message: alertMessage,
      onConfirm: async () => {
        setIsSubmitting(true);

        if (isBuyer) {
          const res = await cancelDeal(dealId, code);

          if (res.status) {
            setIsSubmitting(false);
            refetch();
          } else {
            setIsSubmitting(false);
            toastError();
          }
        } else {
          setInvalidCode(false);
          setCode('');

          const res = await cancelDeal(dealId, code);

          if (res?.data?.two_factor_required) {
            setIsSubmitting(false);
            setTimeout(() => {
              setCancellationRequestModalShown(true);
            }, 1000);
            return;
          }
        }
      },
    });
  }, [dealId, isBuyer, isCancellationRequestModalShown, code]);

  // -------------------------------- Presentational

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-y-auto bg-white px-3 pt-7 pb-10">
      {showSellerRedlight && (
        <div className="absolute top-0 min-h-[30px] w-full bg-red-500 py-3 pl-4 pr-2">
          <p className="text-white">
            <span className="font-bold">IMPORTANT! - </span> It is not yet safe
            for you to send/supply anything as the buyer has not yet paid into
            escrow.
          </p>
        </div>
      )}
      {showSellerGreenlight && (
        <div className="absolute top-0 min-h-[30px] w-full bg-green-500 py-3 pl-4 pr-2">
          <p className="text-white">
            <span className="font-bold">The buyer has paid into escrow.</span>{' '}
            It is now safe for you to ship/supply.
          </p>
        </div>
      )}
      {showSettlementPending && (
        <div className="absolute top-0 min-h-[30px] w-full bg-green-500 py-3 pl-4 pr-2">
          <p className="text-white">
            Settlement request sent. Please wait while we work on final checks
          </p>
        </div>
      )}
      {(showSellerRedlight ||
        showSellerGreenlight ||
        showSettlementPending) && <div className=" h-28 sm:h-32 md:h-14" />}
      <h2 className={'text-xl font-semibold'}>
        Transaction ID:{' '}
        <span className="font-semibold text-primary">
          {dealDetails?.reference}
        </span>
      </h2>

      <div className="flex w-full flex-col border md:w-1/2">
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">Type</span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-center text-lg">
              {' '}
              {dealType} - {isBuyer ? 'Buyer' : 'Seller'}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="break-words text-lg font-semibold text-primary">
              Transaction Status
            </span>
          </div>
          <div className="flex flex-1 flex-row items-center justify-between px-3 py-2">
            <p className="w-full text-center text-lg">
              {' '}
              {(!dealDetails?.other_user?.id
                ? 'Invite Pending'
                : dealDetails?.public_status || ''
              ).toUpperCase()}
            </p>
            {dealDetails.status && (
              <Popover className="relative">
                <Popover.Button
                  type="button"
                  className="inline-flex items-center align-middle"
                >
                  <AiOutlineInfoCircle className="text-primary" />
                </Popover.Button>
                <Popover.Panel
                  className={
                    'absolute left-1/2 z-30 mt-3 w-screen max-w-sm -translate-x-1/2 rounded bg-neutral-300 px-4 text-black'
                  }
                >
                  <div className="absolute left-[11.4rem] top-[-8px] h-5 w-5 rotate-45 bg-neutral-300" />
                  <div className="flex w-full flex-row items-center gap-4 rounded-t-lg bg-neutral-300 p-3 text-black">
                    {transactionStatusExplainer}
                  </div>
                </Popover.Panel>
              </Popover>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="break-words text-lg font-semibold text-primary">
              Payment Status
            </span>
          </div>

          <div className="flex flex-1 flex-row items-center justify-between px-3 py-2">
            <p className="w-full text-center text-lg">
              {' '}
              {dealDetails.public_payment_status
                ? dealDetails?.public_payment_status.toUpperCase()
                : ''}
            </p>
            {dealDetails.payment_status && (
              <Popover className="relative">
                <Popover.Button
                  type="button"
                  className="inline-flex items-center align-middle"
                >
                  <AiOutlineInfoCircle className="text-primary" />
                </Popover.Button>
                <Popover.Panel
                  className={
                    'absolute left-1/2 z-30 mt-3 w-screen max-w-sm -translate-x-1/2 rounded bg-neutral-300 px-4 text-black'
                  }
                >
                  <div className="absolute left-[11.4rem] top-[-8px] h-5 w-5 rotate-45 bg-neutral-300" />
                  <div className="flex w-full flex-col items-center gap-4 rounded-t-lg bg-neutral-300 p-3 text-black">
                    {paymentStatusExplainer}
                  </div>
                </Popover.Panel>
              </Popover>
            )}
          </div>
        </div>

        {dealDetails?.status === 'completed' &&
        dealDetails?.payment_status === 'settled' ? (
          <>
            <div className="flex flex-row justify-between border-b">
              <div className="flex-1 px-3 py-2">
                <span className="text-lg font-semibold text-primary">
                  Settlement Amount
                </span>
              </div>
              <div className="flex-1 px-3 py-2">
                <p className="text-center text-lg">
                  {dealDetails && dealDetails.amount
                    ? `$${parseFloat(dealDetails?.amount)}`
                    : ''}
                </p>
              </div>
            </div>

            {settlementAccountDetails && !isBuyer && (
              <div className="flex flex-row justify-between border-b">
                <div className="flex-1 px-3 py-2">
                  <span className="text-lg font-semibold text-primary">
                    Settlement To
                  </span>
                </div>
                <div className="flex-1 py-2 pr-2">
                  <div className="flex flex-1 flex-col justify-center align-middle text-sm">
                    <div className="flex flex-row justify-between gap-4">
                      <p className="font-semibold">BSB:</p>
                      <p className=" text-right">
                        {settlementAccountDetails.bsb_number || ''}
                      </p>
                    </div>
                    <div className="flex flex-row justify-between gap-4">
                      <p className="font-semibold">Account Number:</p>
                      <p className=" text-right">
                        {settlementAccountDetails.account_number || ''}
                      </p>
                    </div>
                    <div className="flex flex-row justify-between gap-4">
                      <p className="font-semibold">Account Name:</p>
                      <p className=" text-right">
                        {settlementAccountDetails.account_name || ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-row justify-between border-b">
              <div className="flex-1 px-3 py-2">
                <span className="text-lg font-semibold text-primary">
                  Settlement Date
                </span>
              </div>
              <div className="flex-1 px-3 py-2">
                <p className="text-center text-lg">
                  {dealDetails?.updated_at
                    ? format(
                        new Date(dealDetails?.updated_at),
                        DateTimeFormats.DisplayDateTimeShort
                      )
                    : ''}
                </p>
              </div>
            </div>
          </>
        ) : null}

        {['settled', 'paid'].includes(dealDetails?.payment_status) ? (
          <div className="flex flex-row justify-between border-b">
            <div className="flex-1 px-3 py-2">
              <span className="text-lg font-semibold text-primary">
                GoEscrow fee
              </span>
            </div>
            <div className="flex-1 px-3 py-2">
              <p className="text-center text-lg">
                {dealDetails && dealDetails.amount
                  ? `$${Math.abs(
                      parseFloat(dealDetails?.amount_with_fee) -
                        parseFloat(dealDetails?.amount)
                    ).toFixed(2)}`
                  : ''}
              </p>
            </div>
          </div>
        ) : null}

        {dealDetails?.status !== 'completed' ? (
          <div className="flex flex-row justify-between border-b">
            <div className="flex-1 px-3 py-2">
              <span className="text-lg font-semibold text-primary">
                {isBuyer ? 'Transaction Amount' : 'Item Amount'}
              </span>
            </div>
            <div className="flex-1 px-3 py-2">
              <p className="text-center text-lg">
                $
                {parseFloat(
                  isBuyer ? dealDetails?.amount_with_fee : dealDetails?.amount
                )}
              </p>
            </div>
          </div>
        ) : null}

        <div className="flex flex-row justify-between border-b">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">
              Date Created
            </span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-center text-lg">
              {dealDetails?.created_at
                ? format(
                    new Date(dealDetails?.created_at),
                    DateTimeFormats.DisplayDateTimeShort
                  )
                : ''}
            </p>
          </div>
        </div>

        <div className="flex flex-col border-b text-left">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">
              Description
            </span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-lg">{dealDetails?.description}</p>
          </div>
        </div>

        <div className="flex flex-col border-b text-left">
          <div className="flex-1 px-3 py-2">
            <span className="text-lg font-semibold text-primary">
              Other Party GoEscrow ID, Email or Mobile
            </span>
          </div>
          <div className="flex-1 px-3 py-2">
            <p className="text-lg">{otherPartyReference}</p>
          </div>
        </div>

        <div className="flex flex-col border-b text-left">
          <NotesSection notes={dealNotes} dealId={dealId} refetch={refetch} />
        </div>
      </div>
      <div className="mx-3 flex flex-col items-center">
        {isPromptedToPay ? (
          <button className="btn-main" onClick={handleTransferPayment}>
            <h3 className="text-lg text-white">Pay Now</h3>
          </button>
        ) : null}

        {releaseDate &&
          ['accepted', 'pending', 'completed'].includes(dealDetails?.status) &&
          TimedEscrowDisclaimers}

        {showAwaitingDealAcceptance && (
          <>
            <button className="btn-main mt-4" onClick={handleWait}>
              <h3 className="text-lg text-white">Home</h3>
            </button>
            <button
              className="mt-4 rounded-sm bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
              onClick={() => handleCancelPendingDeal()}
            >
              <h3 className="text-white">Cancel</h3>
            </button>
          </>
        )}

        {showCloseDeal ? (
          <>
            {isBuyer && releaseDate && (
              <p className=" mt-6 text-left italic">
                To settle this transaction now rather than wait for the above
                settlement time:
              </p>
            )}
            <div className="mt-3 flex flex-row items-center">
              <input
                id="shareNote"
                type={'checkbox'}
                className="h-4 w-4 border-2"
                checked={isTickedCheckbox}
                onChange={toggleCheckbox}
              />
              <button onClick={toggleCheckbox}>
                <p className="mt-2 ml-4 mr-5 font-light">
                  {isBuyer && releaseDate
                    ? 'I authorize early Final Settlement as I have received the Goods or Services or both and have read the GoESCROW Terms and Conditions.'
                    : 'I authorise GoESCROW to settle funds held in trust to the seller.'}
                </p>
              </button>
            </div>

            <button
              className="btn-main mt-5"
              onClick={handleCloseDeal}
              disabled={isCloseDealDisabled}
            >
              <h3 className="text-white">Settle Transaction</h3>
            </button>
          </>
        ) : null}

        {showCancelRequest && (
          <>
            <button
              className="mt-5 rounded-sm bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
              onClick={handleCancelDeal}
              disabled={isSubmitting}
            >
              <h3 className="text-white">Request Cancellation</h3>
            </button>
          </>
        )}

        {showCancelRequestControls && (
          <>
            <div className="mt-4 flex w-1/2 flex-col gap-2">
              <p className="text-primary">Cancellation Requested</p>
              <button
                className="btn-main"
                onClick={handleAcceptCancellation}
                disabled={isSubmitting}
              >
                Accept
              </button>
            </div>

            <div className="flex w-1/2 flex-row gap-2">
              <button
                className="w-full rounded-sm bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
                onClick={handleWait}
              >
                Wait
              </button>
              <button
                className="w-full rounded-sm bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
                onClick={handleDenyCancellationRequest}
              >
                Deny
              </button>
            </div>
          </>
        )}
      </div>

      {/* close deal modal */}
      <Modal isOpen={isDealClosedModalShown} onClose={handleCloseDealModal}>
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-lg font-medium leading-6 text-primary"
        >
          Transaction Closed
        </Dialog.Title>

        <div className="mt-2 flex flex-col gap-2">
          <p>
            Thank you for completing the transaction. You have received your
            goods and you want to complete the deal.
            <br />
            The seller will be notified by Email, SMS and by App. Check the
            status of this Transaction in &apos;My Transactions&apos; Menu
          </p>
          <button className="btn-main mt-8" onClick={handleCloseDealModal}>
            <h3 className="text-white">Finish</h3>
          </button>
        </div>
      </Modal>

      {/* Release Funds modal */}
      <Modal
        isOpen={isReleaseFundsModalShown}
        onClose={handleCloseReleaseFundsModal}
      >
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-lg font-medium leading-6 text-primary"
        >
          Release Funds
        </Dialog.Title>

        <div className="mt-4 flex flex-col gap-2 text-center">
          <p>
            I have confirm receipt the relevant goods or services from the other
            party and understand that releasing funds is immediate and
            irreversible and irrevocable. I have read and agree to GoESCROW
            Terms and Conditions
          </p>

          <TextInput
            placeholder="Enter Six Digit Code"
            type="number"
            minLength={6}
            maxLength={6}
            value={code}
            disabled={isSubmitting}
            onChange={(e) => {
              setCode(e.currentTarget.value);
            }}
            className="mt-8 py-5 text-center text-2xl"
          />
          <button
            className="btn-main mt-8"
            onClick={handleCloseDeal}
            disabled={isSubmitting || !code || code.length !== 6}
          >
            <h3 className="text-white">Release Funds</h3>
          </button>
          <button
            className="btn-main bg-secondary"
            onClick={handleCloseReleaseFundsModal}
          >
            <h3 className="text-white">Cancel</h3>
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isAcceptCancellationModalShown}
        onClose={() => setCancellationModalShown(false)}
      >
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-lg font-medium leading-6 text-primary"
        >
          Cancel Transaction
        </Dialog.Title>

        <div className="mt-4 flex flex-col gap-2 text-center">
          {isBuyer ? (
            <>
              {dealDetails?.payment_status === 'paid' ? (
                <p>
                  You confirm that ${Math.abs(dealDetails?.amount).toFixed(2)}{' '}
                  held in escrow will be refunded to you. This process is
                  irrevocable and irreversible and you can not dispute it once
                  you enter the 2FA code here.
                </p>
              ) : (
                <p>You confirm to cancel this transaction.</p>
              )}
            </>
          ) : (
            <>
              {dealDetails?.payment_status === 'paid' ? (
                <p>
                  You confirm that ${Math.abs(dealDetails?.amount).toFixed(2)}{' '}
                  held in escrow will be refunded to your customer. This process
                  is irrevocable and irreversible and you can not dispute it
                  once you enter the 2FA code here
                </p>
              ) : (
                <p>You confirm to cancel this transaction.</p>
              )}
            </>
          )}

          <TextInput
            placeholder="Enter Six Digit Code"
            type="number"
            minLength={6}
            maxLength={6}
            value={code}
            disabled={isSubmitting}
            onChange={(e) => {
              setCode(e.currentTarget.value);
            }}
            className="mt-8 py-5 text-center text-2xl"
          />

          <button
            className="btn-main mt-8"
            onClick={handleAcceptCancellation}
            disabled={isSubmitting || !code || code.length !== 6}
          >
            <h3 className="text-white">Request Cancellation</h3>
          </button>

          <button
            className="btn-main bg-secondary"
            onClick={() => setCancellationModalShown(false)}
          >
            <h3 className="text-white">Cancel</h3>
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isCancellationRequestModalShown}
        onClose={() => setCancellationRequestModalShown(false)}
      >
        <Dialog.Title
          as="h3"
          className="items-center justify-center text-center text-lg font-medium leading-6 text-primary"
        >
          CANCELLATION REQUEST CONFIRMATION
        </Dialog.Title>
        <div className="mt-4 flex flex-col gap-2 text-center">
          <p>
            You are requesting cancellation acceptance by your buyer. You are
            also authorising GoESCROW to refund the monies held in escrow for
            you back to them if they accept. This will happen instantly on their
            acceptance. This action is irreversible and irrevocable and you may
            not dispute it after this point. If you have shipped goods or
            services and have not been otherwise paid or compensated or received
            the goods back please be very careful confirming this request. If
            unsure do not proceed and stop this request.
          </p>

          <TextInput
            placeholder="Enter Six Digit Code"
            type="number"
            minLength={6}
            maxLength={6}
            value={code}
            disabled={isSubmitting}
            onChange={(e) => {
              setCode(e.currentTarget.value);
            }}
            className="mt-8 py-5 text-center text-2xl"
          />

          <button
            className="btn-main mt-8"
            onClick={handleCancelDeal}
            disabled={isSubmitting || !code || code.length !== 6}
          >
            <h3 className="text-white">Request Cancellation</h3>
          </button>

          <button
            className="btn-main bg-secondary"
            onClick={() => setCancellationRequestModalShown(false)}
          >
            <h3 className="text-white">Cancel</h3>
          </button>
        </div>
      </Modal>

      {ConfirmationModal}
    </div>
  );
};

export default TransactionDetails;
