/* eslint-disable quotes */
/* eslint camelcase: 0 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Dialog } from '@headlessui/react';
import Modal from 'components/Modal';
import { useUserRequests } from 'hooks/user-hooks';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheckCircle } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { checkSufficientIdentification } from 'utils/helpers';
import DriverLicenseForm from './DriverLicenseForm';
import MedicareForm from './MedicareForm';
import PassportForm from './PassportForm';

type Props = {
  refetch?: any;
  data?: any;
  readonly?: boolean;
  isUpdate?: boolean;
};

export const DocumentDetails: React.FC<Props> = ({
  data,
  refetch,
  isUpdate = false,
  readonly,
}) => {
  const { doLoginNavigations } = useUserRequests();

  const [isModalOpen, setIsModalOpen] = useState<
    'license' | 'medicare' | 'passport' | undefined
  >();
  const isLicenseComplete =
    data && data.driver_license && data.driver_license.license_number;
  const isMedicareComplete =
    data && data.medicare && data.medicare.document_number;
  const isPassportComplete =
    data && data.passport && data.passport.document_number;

  // useEffect(() => {
  //   if (!isUpdate && checkSufficientIdentification(data)) {
  //     doLoginNavigations();
  //   }
  // }, [data, isUpdate]);

  const closeModal = useCallback(() => {
    setIsModalOpen(undefined);
  }, []);

  const handleForm = useCallback(
    (form: 'license' | 'medicare' | 'passport') => () => {
      if (readonly) return;
      setIsModalOpen(form);
    },
    [readonly]
  );

  const handleFinish = useCallback(() => {
    if (refetch) refetch();
    closeModal();
  }, [refetch, closeModal]);

  const ModalContent = useMemo(() => {
    if (!isModalOpen) return <></>;
    switch (isModalOpen) {
      case 'license':
        return <DriverLicenseForm onFinish={handleFinish} />;
      case 'medicare':
        return <MedicareForm onFinish={handleFinish} />;
      case 'passport':
        return <PassportForm onFinish={handleFinish} />;
    }
  }, [isModalOpen]);

  return (
    <div>
      <h3 className="text-2xl">Personal Identification Check</h3>
      <p className="mt-2 text-neutral-700">
        Please provide two Australian Government issued identification document
        references from this list:
      </p>
      <h3 className="mb-5 mt-9 text-lg text-primary">ID References</h3>

      <div className="flex w-full flex-col gap-3">
        <button
          className="flex flex-row items-center justify-between bg-white p-4 shadow-lg ring-1 ring-neutral-100 disabled:bg-neutral-100 disabled:text-neutral-500"
          disabled={isLicenseComplete}
          onClick={handleForm('license')}
        >
          <p>Driver&apos;s License</p>
          {isLicenseComplete ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FiPlus className="text-2xl" />
          )}
        </button>

        <button
          className="flex flex-row items-center justify-between bg-white p-4 shadow-lg ring-1 ring-neutral-100 disabled:bg-neutral-100 disabled:text-neutral-500"
          disabled={isMedicareComplete}
          onClick={handleForm('medicare')}
        >
          <p>Medicare</p>
          {isMedicareComplete ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FiPlus className="text-2xl" />
          )}
        </button>

        <button
          className="flex flex-row items-center justify-between bg-white p-4 shadow-lg ring-1 ring-neutral-100 disabled:bg-neutral-100 disabled:text-neutral-500"
          disabled={isPassportComplete}
          onClick={handleForm('passport')}
        >
          <p>Passport</p>
          {isPassportComplete ? (
            <FaCheckCircle className="text-success" />
          ) : (
            <FiPlus className="text-2xl" />
          )}
        </button>
      </div>
      <Modal isOpen={isModalOpen !== undefined} onClose={closeModal}>
        <Dialog.Title className="text-xl font-semibold text-primary">
          Identification Documents
        </Dialog.Title>
        {ModalContent}
      </Modal>
    </div>
  );
};

export default DocumentDetails;
