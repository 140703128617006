import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/FormInput';
import { addDays, format } from 'date-fns';
import { useUserRequests } from 'hooks/user-hooks';
import React, { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { DateTimeFormats } from 'utils/parsers';
import { toastError } from 'utils/toast';
import * as yup from 'yup';

type Props = {
  onFinish: () => void;
};

const PassportForm: React.FC<Props> = ({ onFinish }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { uploadPassport } = useUserRequests();

  const schema = yup
    .object({
      passport_number: yup.string().required('Passport number required'),
      first_name: yup.string().required('First Name required'),
      middle_name: yup.string(),
      last_name: yup.string().required('Last Name required'),
      nationality: yup.string().required('Nationality required'),
      birth_date: yup
        .date()
        .max(addDays(new Date(), -1), 'Birth date cannot be present or future')
        .required('Birth date is required'),
      issue_date: yup
        .date()
        .max(addDays(new Date(), -1), 'Issue date cannot be present or future')
        .required('Issue date is required'),
      expiry_date: yup
        .date()
        .min(addDays(new Date(), 1), 'Expiry date cannot be present or past')
        .required('Expiry date is required'),
      birth_place: yup.string().required('Birth place required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    // ...(data ? { defaultValues: data } : {}),
  });
  const expiryDateValue = watch('expiry_date');
  const issueDateValue = watch('issue_date');
  const birthDateValue = watch('birth_date');

  const handleBirthDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('birth_date', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleExpiryDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('expiry_date', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleIssueDatePickConfirm = useCallback(
    (date: Date) => {
      setValue('issue_date', date, { shouldValidate: true });
    },
    [setValue]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      setIsSubmitting(true);

      const body = {
        document_number: formData.passport_number,
        first_name: formData.first_name,
        middle_name: formData.middle_name,
        last_name: formData.last_name,
        nationality: formData.nationality,
        date_of_birth: format(formData.birth_date, DateTimeFormats.FormDate),
        date_of_issue: format(formData.issue_date, DateTimeFormats.FormDate),
        date_of_expiration: format(
          formData.expiry_date,
          DateTimeFormats.FormDate
        ),
        place_of_birth: formData.birth_place,
      };

      const res = await uploadPassport(body);

      setIsSubmitting(false);
      if (res.status) {
        onFinish();
      } else {
        toastError();
      }
    },
    [uploadPassport]
  );

  return (
    <div>
      <h2 className="mb-7 text-xl">Passport Information</h2>

      <div className="flex-1 flex-col space-y-3">
        <FormInput
          control={control}
          name="passport_number"
          label="Passport Number"
          type="text"
          placeholder="Passport Document Number"
          errorMessage={errors.passport_number?.message as string}
        />

        <FormInput
          control={control}
          name="first_name"
          label="Firs Name"
          type="text"
          placeholder="First Name"
          errorMessage={errors.first_name?.message as string}
        />

        <FormInput
          control={control}
          name="middle_name"
          label="Middle Name"
          type="text"
          placeholder="Middle Name"
        />

        <FormInput
          control={control}
          name="last_name"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          errorMessage={errors.last_name?.message as string}
        />

        <FormInput
          control={control}
          name="nationality"
          label="Nationality"
          type="text"
          placeholder="Nationality"
          errorMessage={errors.nationality?.message as string}
        />

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Birth</label>
          <ReactDatePicker
            selected={birthDateValue}
            onChange={handleBirthDatePickConfirm}
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.birth_date && errors.birth_date?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.birth_date.message}
            </span>
          ) : null}
        </div>

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Issue</label>
          <ReactDatePicker
            selected={issueDateValue}
            onChange={handleIssueDatePickConfirm}
            showYearDropdown
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            yearDropdownItemNumber={15}
            maxDate={new Date()}
            scrollableYearDropdown
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.issue_date && errors.issue_date?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.issue_date.message}
            </span>
          ) : null}
        </div>

        <div>
          <label className="pl-1 text-sm text-secondary">Date of Expiry</label>
          <ReactDatePicker
            selected={expiryDateValue}
            onChange={handleExpiryDatePickConfirm}
            showYearDropdown
            dateFormatCalendar="MMMM"
            dateFormat={DateTimeFormats.DisplayDateShort}
            yearDropdownItemNumber={15}
            minDate={new Date()}
            scrollableYearDropdown
            className="min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary"
          />
          {errors.expiry_date && errors.expiry_date?.message ? (
            <span className="pl-1 text-xs leading-none text-red-500">
              {'* ' + errors.expiry_date.message}
            </span>
          ) : null}
        </div>

        <FormInput
          control={control}
          name="birth_place"
          label="Place of Birth"
          type="text"
          placeholder="Place of Birth"
          errorMessage={errors.birth_place?.message as string}
        />
      </div>

      <button
        className="btn-main mt-10 w-full"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit(handleContinue)}
      >
        Continue
      </button>
    </div>
  );
};

export default PassportForm;
