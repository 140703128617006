import { Dialog } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import Modal from 'components/Modal';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';

const AcceptDeal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dealId = useMemo(
    () => location?.state?.dealId || undefined,
    [location.state]
  );

  const [isConfirmDeclineModalVisible, setConfirmDeclineModalVisible] =
    useState<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { getDealDetails, acceptDeal, declineDeal, dealNavigationHelper } =
    useDealsRequests();

  const { getUserDetails } = useUserRequests();

  const { data: dealData, refetch } = useQuery(['dealDetails', dealId], () =>
    getDealDetails(dealId)
  );

  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const dealDetails = useMemo(
    () => (dealData && dealData.status ? dealData.data : {}),
    [dealData]
  );
  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const isInitiator = useMemo(
    () => dealDetails && dealDetails.initiator === 'me',
    [dealDetails]
  );

  const isBuyer = useMemo(
    () =>
      (isInitiator && dealDetails?.transaction_type === 'send') ||
      (!isInitiator && dealDetails?.transaction_type === 'request'),
    [dealDetails]
  );

  useEffect(() => {
    if (dealDetails && dealDetails.status && dealDetails.status !== 'pending') {
      dealNavigationHelper(dealDetails, userDetails, true);
    }
  }, [dealDetails]);

  const handleAccept = useCallback(async () => {
    const res = await acceptDeal(dealId);
    if (res.status) {
      refetch();
    } else {
      toastError();
    }
  }, [dealId, dealDetails]);

  const handleWait = useCallback(() => {
    navigate(ROUTES.HOME);
  }, []);

  const handleShowDeclineModal = useCallback(() => {
    setConfirmDeclineModalVisible(true);
  }, []);

  const handleDecline = useCallback(async () => {
    setConfirmDeclineModalVisible(false);
    setSubmitting(true);
    const res = await declineDeal(dealId);
    if (res.status) {
      navigate(ROUTES.HOME);
      setSubmitting(false);
    } else {
      toastError();
      setSubmitting(false);
    }
  }, [dealId]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 bg-white py-6 px-5">
      <div className="flex flex-col text-center">
        <h2 className="text-xl font-semibold">Accept Transaction</h2>
        <p className="text-sm">
          {isBuyer
            ? 'Another party has requested you to enter into a GoESCROW transaction with them acting as the seller or supplier of services.'
            : 'Another party has requested you to enter into a GoESCROW transaction with them with you acting as the seller or supplier of services.'}
        </p>
        <p className="text-sm">
          {isBuyer
            ? 'If you agree to buy the item/service as described below you can accept this transaction.'
            : ' If you agree to supply the item as described for the item price listed you can accept this transaction.'}
        </p>
      </div>

      <div className="mt-3 flex w-10/12 flex-col gap-2 sm:w-1/2">
        <label className="text-lg font-semibold text-primary">
          Other Party Details
        </label>
        <div className="flex w-full flex-row justify-between border bg-white px-4 py-3 shadow-lg">
          <p className="font-medium">{dealDetails?.user?.reference}</p>
        </div>
      </div>

      <div className="mt-3 flex w-10/12 flex-col gap-2 sm:w-1/2">
        <p className="text-lg font-semibold text-primary">Item Detail</p>
        <div className="flex w-full flex-row justify-between border bg-white px-4 py-3 shadow-lg">
          <p className="font-medium">Description</p>
          <p className="font-medium">{dealDetails?.description}</p>
        </div>

        <div className="flex w-full flex-row justify-between border bg-white px-4 py-3 shadow-lg">
          <p className="font-medium">Item Price</p>
          <p className="font-medium">
            ${parseFloat(dealDetails?.amount).toFixed(2)}
          </p>
        </div>

        <h2 className="mt-2 text-right text-xl font-bold text-primary">
          Total ${parseFloat(dealDetails?.amount).toFixed(2)}
        </h2>
      </div>

      <div className="mt-4 flex w-1/2 flex-col gap-2">
        <button className="btn-main" onClick={handleAccept}>
          Accept
        </button>
      </div>

      <>
        <div className="flex w-10/12 flex-row gap-2 sm:w-1/2">
          <button
            className="w-full rounded-sm bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
            onClick={handleWait}
          >
            Wait
          </button>
          <button
            className="w-full rounded-sm bg-secondary px-4 py-3 font-bold text-white hover:bg-secondary-120"
            onClick={handleShowDeclineModal}
          >
            Decline
          </button>
        </div>
      </>

      <Modal
        isOpen={isConfirmDeclineModalVisible}
        onClose={() => {
          setConfirmDeclineModalVisible(false);
        }}
      >
        <Dialog.Title
          as="h3"
          className="text-lg font-medium leading-6 text-primary"
        >
          Confirm Decline
        </Dialog.Title>

        <div className="mt-4">
          Are you sure you want to decline this transaction?
        </div>

        <div className="mt-8 flex flex-row-reverse items-end gap-4">
          <button
            onClick={handleDecline}
            className="btn-main"
            disabled={isSubmitting}
          >
            Yes
          </button>

          <button
            onClick={() => {
              setConfirmDeclineModalVisible(false);
            }}
            className="rounded-lg bg-secondary py-3 px-4 font-bold text-white hover:bg-secondary-120"
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AcceptDeal;
