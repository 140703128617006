import React from 'react';
import heroimg from 'assets/images/home-banner.svg';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import styles from 'utils/styles';
import { Link } from 'react-router-dom';

const GetStarted = () => {
  return (
    <div className="flex w-full flex-col py-5 px-2 sm:flex-row">
      <img
        src={heroimg}
        alt="Hero"
        className="my-5 flex h-auto w-auto flex-1 self-center"
      />

      <div
        className={`flex flex-1 flex-col ${styles.flexCenter} mt-5 text-center sm:mt-0 sm:items-start sm:text-left`}
      >
        <h1 className="text-2xl font-semibold text-secondary">
          Welcome to Payment Protection Platform
        </h1>

        <p className="mt-4 text-lg font-light">
          Welcome to GoEscrow, your Payment Protection Partner. De-risked
          trnsactions for buyers and sellers.
        </p>

        <Link to="/login" className="mt-4 w-full self-start sm:w-auto">
          <button className="btn-main self-start py-2">
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Get Started</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GetStarted;
