/* eslint camelcase: 0 */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';
import { parseBsbNumber } from 'utils/parsers';
import TextInput from 'components/TextInput';
import { toastError } from 'utils/toast';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers/routes';

type Props = {
  onSubmit?: (data: any) => void;
  onVerify?: (data: any) => void;
  data?: any;
  readonly?: boolean;
  isUpdate?: boolean;
  setEditMode?: Dispatch<SetStateAction<'view' | 'edit' | undefined>>;
};

enum Steps {
  INPUT = 'input',
  VERIFY = 'verify',
  VERIFIED = 'verified',
}

const BankDetails: React.FC<Props> = ({
  onSubmit,
  onVerify,
  data,
  readonly,
  isUpdate,
  setEditMode,
}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const [isOverride, setOverride] = useState<boolean>(false);
  const schema = yup
    .object({
      bsb_number: yup.string().required('BSB Number required'),
      account_number: yup.string().required('Account Number required'),
      account_name: yup.string().required('Account Name required'),
    })
    .required();

  const step = useMemo(() => {
    if (!data || !data.bsb_number || isOverride) return Steps.INPUT;
    if (data.bsb_number && !data.is_verified) return Steps.VERIFY;
    if (data.bsb_number && data.is_verified) return Steps.VERIFIED;
  }, [data, isOverride]);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    ...(data ? { defaultValues: data } : {}),
  });

  const handleChangeText = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setCode(e.currentTarget.value);
    },
    [setCode]
  );

  const handleContinue = useCallback(
    async (formData: any) => {
      if (!onSubmit || !formData) return;

      onSubmit({
        ...(formData ? formData : data),
        bsb_number: parseBsbNumber(
          formData ? formData.bsb_number : data.bsb_number
        ),
      });
      setOverride(false);
    },
    [onSubmit]
  );

  const handleVerify = useCallback(async () => {
    if (step !== Steps.VERIFY) return;
    else if (!code) {
      toastError('Verification code is required');
    } else if (!code || code.length <= 0 || code.length > 8) {
      toastError('Invalid code');
      setCode('');
    } else {
      if (onVerify) onVerify(code);
    }
  }, [code, step]);

  const handleOverride = () => {
    setOverride(true);
    if (setEditMode) {
      setEditMode('edit');
    }
  };

  const handleSkip = useCallback(async () => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  if (step === Steps.VERIFIED && readonly) {
    return (
      <div className="my-3 flex w-full flex-col gap-6">
        <p className="text-neutral-700">
          Your bank account is{' '}
          <span className="font-semibold text-success">verified.</span>
        </p>
        <div className="flex-1 flex-col space-y-3">
          <TextInput
            disabled
            label="BSB Number"
            type="text"
            value={data?.bsb_number && parseBsbNumber(data.bsb_number)}
          />
          <TextInput
            disabled
            label="Account Number"
            type="text"
            value={data?.account_number}
          />
          <TextInput
            disabled
            label="Account Name"
            type="text"
            value={data?.account_name}
          />
        </div>
        <div>
          <h2 className="mt-3 mb-0 font-semibold text-primary">
            Your GoEscrow Deposit Details
          </h2>
          <p className="mt-0">
            These are your personal GoESCROW bank account details for deposits
            into escrow.
          </p>

          <div className="mx-3 mt-3 flex flex-col gap-4">
            {/* TODO: Hide until available */}
            {/* <div className="flex w-full flex-col px-4 py-3 shadow-md">
              <div className="flex w-full flex-row justify-between">
                <h4 className="font-medium text-primary">AZUPay - PayTO</h4>
                <FiArrowRight className="ml-3 text-xl text-primary" />
              </div>
              <p className="mt-1">Authorise a payment via PayTO</p>
            </div> */}

            <div className="flex flex-col px-4 py-3 shadow-md">
              <h4 className="font-medium text-primary">EFT</h4>
              <p className="mt-1">
                Please log into your Internet banking and make deposits via EFT
                to your individual GoEscrow bank account as follows:
              </p>
              <div className="my-2 ml-3 border-l-2 border-l-primary p-2">
                <p className="text-neutral-600">
                  Bank Account Name:{'  '}
                  <span className="text-primary">GoEscrow Pty Ltd</span>
                </p>
                <p className="text-neutral-600">
                  Care of:{'  '}
                  <span className="text-primary">
                    Commonwealth Bank of Australia
                  </span>
                </p>
                <p className="text-neutral-600">
                  SWIFT Code:{'  '}
                  <span className="text-primary">CTBAAU2S</span>
                </p>
                <p className="text-neutral-600">
                  Bank Account BSB:{'  '}
                  <span className="text-primary">064 152</span>
                </p>
                <p className="text-neutral-600">
                  Account Number:{'  '}
                  <span className="text-primary">1045 9623</span>
                </p>
              </div>
              <p>
                Please include the Transaction ID in the EFT reference field.
              </p>
            </div>

            <div className="flex flex-col px-4 py-3 shadow-md">
              <h4 className="font-medium text-primary">PayID</h4>
              <p className="mt-1">
                Please log into your Internet Banking and make a deposit via
                PayID into your GoEscrow PayID account as follows:
              </p>
              <div className="my-2 ml-3 border-l-2 border-l-primary p-2">
                <p className="text-neutral-600">ACN PayID:</p>
                <p className="text-primary">650 709 354</p>
              </div>
              <p>Please include the Transaction ID in the PayID Description.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (step === Steps.VERIFY) {
    return (
      <div className="my-3 flex w-full flex-col gap-y-6">
        <p className="mt-2 text-center text-neutral-700 sm:text-left">
          <p className="my-3 font-semibold">
            We sent a verification code to your bank account{' '}
            <span className="italic text-primary">
              (BSB: {data?.bsb_number ? parseBsbNumber(data.bsb_number) : ''},
              Account: {data?.account_number || ''})
            </span>
            . Enter below the reference/verification code once received.
          </p>
          <p
            className="my-3 cursor-pointer font-semibold text-primary underline"
            onClick={handleOverride}
          >
            If the bank account is wrong, please click to re-enter.
          </p>
          The transaction may take a few hours or overnight to reflect in your
          account. You may skip this step and continue later once your banking
          institution sends through the transaction. You continue by selecting
          the bank details icon in the app menu.
        </p>

        <>
          <TextInput
            className="w-full items-center space-x-3 text-center text-2xl tracking-widest sm:w-1/2"
            value={code}
            onChange={handleChangeText}
            maxLength={6}
            label="Verification Code"
            labelClassname="font-semibold"
            max={6}
            type="number"
            placeholder="* * * * * *"
          />

          <div className="mt-6 w-full sm:w-1/2">
            <button
              disabled={!code || code.length <= 0 || code.length > 8}
              className="btn-main w-full self-center py-2 sm:self-start"
              onClick={handleVerify}
            >
              <div className="flex flex-row justify-center">
                <h2 className="text-xl font-semibold">Verify</h2>
                <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
              </div>
            </button>
          </div>
        </>
        {!isUpdate ? (
          <div className="w-full sm:w-1/2 sm:pr-2">
            <button
              onClick={handleSkip}
              className="w-full self-center rounded-sm bg-transparent py-2 px-4 font-bold shadow-lg ring-2 ring-primary sm:self-start"
            >
              <div className="flex flex-row justify-center">
                <h2 className="text-xl font-semibold text-primary">Skip</h2>
              </div>
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <form className="my-3 w-full" onSubmit={handleSubmit(handleContinue)}>
      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="flex-1 flex-col space-y-3">
          <FormInput
            control={control}
            disabled={readonly}
            name="bsb_number"
            label="BSB Number"
            type="text"
            placeholder="BSB Number (e.g 999-999)"
          />
          <FormInput
            control={control}
            disabled={readonly}
            name="account_number"
            label="Account Number"
            type="number"
            placeholder="Account Number"
          />
          <FormInput
            control={control}
            disabled={readonly}
            name="account_name"
            label="Account Name"
            type="text"
            placeholder="Account Name"
          />
        </div>
      </div>

      {!readonly ? (
        <div className="mt-6 w-full sm:w-1/2 sm:pr-2">
          <button
            disabled={!isValid}
            className="btn-main w-full self-center py-2 sm:self-start"
          >
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold">Continue</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-3xl text-white" />
            </div>
          </button>
        </div>
      ) : null}
      {!isUpdate ? (
        <div className="mt-6 w-full sm:w-1/2 sm:pr-2">
          <button
            onClick={handleSkip}
            className="w-full self-center bg-transparent py-2 ring-2 ring-primary sm:self-start"
          >
            <div className="flex flex-row justify-center">
              <h2 className="text-xl font-semibold text-primary">Skip</h2>
            </div>
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default BankDetails;
