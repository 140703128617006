import { Link, Outlet } from 'react-router-dom';

const Auth = () => {
  return (
    <div className="flex min-h-screen flex-1 flex-col items-center justify-between bg-offwhiteBG pt-0 pb-10 sm:pt-14 sm:pb-0">
      <div className="min-h-screen w-full items-center justify-center sm:h-auto sm:min-h-1/4 sm:w-[768px]">
        <Outlet />
      </div>
      <div className="mb-3 mt-10 flex w-full flex-col justify-center text-center font-light text-secondary">
        <p className="text-xs text-gray-600">
          ©{new Date().getUTCFullYear()}. GoESCROW Pty Ltd. ABN 26 650 709 354
          <br />
          All Rights Reserved.
        </p>
        <div className="flex flex-row justify-center gap-4 text-center text-xs text-gray-900">
          <a href="https://goescrow.com.au/tsandcs/">Terms Of Use</a>
          <a href="https://goescrow.com.au/privacy-policy/">Privacy Policy</a>
          <a href="https://goescrow.com.au/">Company Home</a>
        </div>
      </div>
    </div>
  );
};

export default Auth;
