import TextInput from 'components/TextInput';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styles from 'utils/styles';

import logoHorizontal from 'assets/images/logo-withtext-horizontal.png';
import { useUserRequests } from 'hooks/user-hooks';
import { ROUTES } from 'routers/routes';
import { toastError } from 'utils/toast';
import { useAmplifyAuth } from 'hooks/auth-hooks';

enum Steps {
  PROMPT = 'prompt',
  INPUT = 'input',
  SUCCESS = 'success',
}

const TwoFactorMobile: React.FC = () => {
  const [step, setStep] = useState<Steps>(Steps.PROMPT);
  const [code, setCode] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { verifyPhone } = useUserRequests();
  const { getAuth } = useAmplifyAuth();
  const navigate = useNavigate();
  // const [resendActiveTime, setResendActiveTime] = useState<Date>(
  //   add(new Date(), { minutes: 1 })
  // );

  // const [resendActive, setResendActive] = useState<boolean>(false);

  const isVerifyDisabled = useMemo(() => {
    if ((step === Steps.INPUT && (!code || code.length < 6)) || isSubmitting)
      return true;
    return false;
  }, [code, isSubmitting]);

  useEffect(() => {
    guard();
  }, []);

  const guard = async () => {
    const authData = await getAuth();
    if (!authData) navigate(ROUTES.LOGIN);
  };

  const handleVerifyNow = useCallback(async () => {
    setIsSubmitting(true);
    const res = await verifyPhone(code);
    if (res.data && res.data.two_factor_validated) {
      setIsSubmitting(false);
      setStep(Steps.SUCCESS);
    } else {
      toastError();
      setIsSubmitting(false);
    }
  }, [code]);

  const handlePrompt = useCallback(async () => {
    setIsSubmitting(true);
    const res = await verifyPhone();
    setIsSubmitting(false);
    if (res.data && res.data.two_factor_sent) {
      setStep(Steps.INPUT);
    } else {
      toastError();
    }
  }, [step]);

  const handleContinue = useCallback(() => {
    navigate(ROUTES.VERIFICATION_PROMPT);
  }, []);

  // const handleResendConfirmationCode = useCallback(async () => {
  //   try {
  //     await resendConfirmSignUp(email || '');
  //     setResendActive(false);
  //     setResendActiveTime(add(new Date(), { minutes: 5 }));
  //   } catch (e) {
  //     toastError();
  //     setResendActiveTime(add(new Date(), { seconds: 15 }));
  //     setResendActive(false);
  //   }
  // }, [setResendActive, setResendActiveTime, email]);

  const Content = useMemo(() => {
    switch (step) {
      case Steps.INPUT:
        return (
          <div>
            <h1 className="text-lg font-semibold text-secondary sm:text-3xl">
              Mobile Verification
            </h1>
            <h2 className="mt-1 font-light">
              Enter 6-digit number that was sent to your Mobile Number
            </h2>

            <TextInput
              value={code}
              onChange={(e) => setCode(e.currentTarget.value)}
              containerClassName="w-full"
              className="mt-4 py-2 text-center text-2xl font-medium tracking-widest"
              id="otp"
              name="otp"
              minLength={6}
              maxLength={6}
              required
              placeholder="••••••"
            />
          </div>
        );

      case Steps.PROMPT:
        return (
          <div>
            <h1 className="text-lg font-semibold text-secondary sm:text-3xl">
              Mobile Verification
            </h1>
            <h2 className="mt-1 font-light">
              A six-digit number code will be sent to your phone number for
              verification.
            </h2>
          </div>
        );

      case Steps.SUCCESS:
        return (
          <div>
            <h1 className="text-3xl font-semibold text-secondary  sm:text-3xl">
              Congratulations
            </h1>
            <h2 className="mt-2 text-xl font-light">
              Account has been created. In order to send and receive Escrow we
              need to verify your identity.
            </h2>
          </div>
        );
    }
  }, [code, step]);

  const ButtonText = useMemo(() => {
    switch (step) {
      case Steps.INPUT:
        return 'Verify Now';
      case Steps.PROMPT:
      case Steps.SUCCESS:
        return 'Continue';
    }
  }, [step]);

  const ButtonAction = useMemo(() => {
    switch (step) {
      case Steps.PROMPT:
        return handlePrompt;
      case Steps.INPUT:
        return handleVerifyNow;
      case Steps.SUCCESS:
        return handleContinue;
    }
  }, [step, handlePrompt, handleVerifyNow, handleContinue]);

  return (
    <div className="flex flex-1 items-center justify-center">
      <div
        className={`flex w-10/12 flex-col ${styles.flexCenter} mt-5 space-y-6 text-center sm:w-[45%]`}
      >
        <img
          src={logoHorizontal}
          alt="GoEscrow Logo"
          className="mt-10 mb-5 flex h-auto w-[50%] flex-1 self-center"
        />
        {Content}

        <button
          onClick={ButtonAction}
          disabled={step !== Steps.SUCCESS && isVerifyDisabled}
          className="btn-main mt-4 w-full self-center py-2 sm:self-start"
        >
          <div className="flex flex-row justify-center">
            <h2 className="text-lg font-semibold">{ButtonText}</h2>
            <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default TwoFactorMobile;
