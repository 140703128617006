import React from 'react';
import promoPlaceholder from 'assets/images/promo-placeholder.svg';
import styles from 'utils/styles';

const SpecialOfferWidget = () => {
  return (
    <div className="flex h-[300px] min-w-[300px] flex-col items-center justify-center rounded-sm bg-white drop-shadow-xl">
      <img
        src={promoPlaceholder}
        alt="Goescrow Cycle"
        className={`${styles.fullImage} max-h-[230px] px-5`}
      />
    </div>
  );
};

export default SpecialOfferWidget;
