import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import {
  FiEye,
  FiEyeOff,
  FiLock,
  FiMail,
  FiPhone,
  FiUser,
} from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import logofull from 'assets/images/logo-full.svg';
import FormInput from 'components/FormInput';
import { useAmplifyAuth } from 'hooks/auth-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import phone from 'phone';
import { FieldValues, useForm, useFormState } from 'react-hook-form';
import { ROUTES } from 'routers/routes';
import styles from 'utils/styles';
import { toastError } from 'utils/toast';
import { validatePassword } from 'utils/validations';

const Registration = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const { signUp } = useAmplifyAuth();
  const { lookupGoEscrowUser } = useUserRequests();

  const schema = yup
    .object({
      firstName: yup.string().required('First Name required'),
      lastName: yup.string().required('Last Name required'),
      email: yup.string().email('Invalid email').required('Email required'),
      phoneNumber: yup
        .string()
        .min(4, 'Invalid phone number')
        .max(15, 'Invalid AU phone number')
        .matches(
          /^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/g,
          'Invalid phone number'
        )
        .required('Phone Number required'),
      password: yup
        .string()
        .test(
          'is-valid-password',
          'Your password must contain uppercase and lowercase letters, at least one number, and at least one symbol.',
          validatePassword
        )
        .required('Password required'),
    })
    .required();

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = useFormState({ control });

  const handleSignUp = useCallback(
    async (data: FieldValues) => {
      if (!data || !isValid) return;
      setIsLoading(true);

      data.phoneNumber = phone(data.phoneNumber, {
        country: 'AUS',
        validateMobilePrefix: false,
      }).phoneNumber;

      if (!data.phoneNumber) {
        setIsLoading(false);
        return;
      }

      const checkEmail = await lookupGoEscrowUser(data.email);
      if (checkEmail.status === 200) {
        setIsLoading(false);
        toastError('Email already exists.');
        return;
      }

      const checkPhone = await lookupGoEscrowUser(data.phoneNumber);
      if (checkPhone.status === 200) {
        setIsLoading(false);
        toastError('Phone number already exists.');
        return;
      }

      const user = await signUp(data);
      setIsLoading(false);
      if (user && !user.error) {
        navigate(ROUTES.TWO_FACTOR, { state: { email: data.email || '' } });
      } else {
        if (user?.error?.code === 'UsernameExistsException') {
          toastError(
            'This email address is active. Please login or reset password'
          );
        } else {
          toastError();
        }
      }
    },
    [isValid]
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <div className="flex flex-1 items-center justify-center">
      <div
        className={`flex w-10/12 flex-col ${styles.flexCenter} mt-5 space-y-6 text-center sm:w-[45%]`}
      >
        <img
          src={logofull}
          alt="GoEscrow Logo"
          className="my-10 flex h-[40%] w-[40%] flex-1 self-center"
        />
        <div>
          <h1 className=" text-lg font-semibold text-secondary">
            Create Account
          </h1>
          <h2 className="font-light">Enter Your Login Credentials</h2>
        </div>

        <form className="w-full" onSubmit={handleSubmit(handleSignUp)}>
          <div className="space-y-3">
            <FormInput
              control={control}
              name="firstName"
              type="text"
              autoComplete="fname"
              required
              placeholder="Enter First Name"
              errorMessage={errors.firstName?.message as string}
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiUser className="text-secondary" />
                </div>
              }
            />
            <FormInput
              control={control}
              name="lastName"
              type="text"
              autoComplete="lname"
              required
              placeholder="Enter Last Name"
              errorMessage={errors.lastName?.message as string}
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiUser className="text-secondary" />
                </div>
              }
            />
            <FormInput
              control={control}
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder="Enter Email Address"
              errorMessage={errors.email?.message as string}
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiMail className="text-secondary" />
                </div>
              }
            />
            <FormInput
              control={control}
              name="phoneNumber"
              type="tel"
              required
              placeholder="Enter Phone Number"
              errorMessage={errors.phoneNumber?.message as string}
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiPhone className="text-secondary" />
                </div>
              }
            />
            <FormInput
              control={control}
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              required
              placeholder="••••••••"
              containerClassName="mt-3"
              errorMessage={errors.password?.message as string}
              prefixComponent={
                <div
                  className={`${styles.flexCenter} rounded-full bg-gray-300 p-2 `}
                >
                  <FiLock className="text-secondary" />
                </div>
              }
              suffixComponent={
                <button
                  type="button"
                  className={`${styles.flexCenter} pointer-events-auto`}
                  onClick={toggleShowPassword}
                  tabIndex={-1}
                >
                  {showPassword ? (
                    <FiEye className="mr-2 text-primary" />
                  ) : (
                    <FiEyeOff className="mr-2 text-gray-500" />
                  )}
                </button>
              }
            />
          </div>

          {/* Temporary navigate, replace with button type submit later on */}
          <button
            className="btn-main mt-8 w-full py-2"
            disabled={!isValid || isLoading}
          >
            <div className="flex flex-row items-center justify-center">
              <h2 className="text-lg font-semibold">Create Account</h2>
              <BsFillArrowRightCircleFill className="ml-3 text-2xl text-white" />
            </div>
          </button>
        </form>
        <p className="w-full text-center font-light">
          Already have an account?{' '}
          <span className="font-medium text-primary">
            <Link to="/login">Sign In</Link>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Registration;
