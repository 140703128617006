import 'react-datepicker/dist/react-datepicker.css';

import { Dialog } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useQuery } from '@tanstack/react-query';
import { add, addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import phone from 'phone';
import { useCallback, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import sendFundsSVG from 'assets/images/send-funds-screen.svg';
import { Dropdown } from 'components/Dropdown';
import FormInput from 'components/FormInput';
import Modal from 'components/Modal';
import { useDealsRequests } from 'hooks/deals-hooks';
import { useUserRequests } from 'hooks/user-hooks';
import { DateTimeFormats } from 'utils/parsers';
import { toastError } from 'utils/toast';
import { validatePhoneOrEmail } from 'utils/validations';

const EscrowTypes = ['Anytime', 'Timed Escrow'];

const SendFunds = () => {
  const [isStep2, setStep2] = useState(false);

  const [selectValue, setSelectValue] = useState('Anytime');
  const [dateValue, setDateValue] = useState(addDays(new Date(), 1));

  const [isConfirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);

  const [goEscrowUser, setGoEscrowUser] = useState<any>(null);
  const [lookupError, setLookupError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getDealFees, createDeal, getDealDetails, dealNavigationHelper } =
    useDealsRequests();
  const { getUserDetails, lookupGoEscrowUser } = useUserRequests();

  const { data: feesRes, isLoading: feesLoading } = useQuery(
    ['dealFees'],
    getDealFees
  );
  const { data: userData } = useQuery(['userDetails'], getUserDetails);

  const feesData = useMemo(
    () => (feesRes && feesRes.status ? feesRes.data : {}),
    [feesRes]
  );
  const userDetails = useMemo(
    () => (userData && userData.status ? userData.data : {}),
    [userData]
  );

  const dateTimeMinimum = useMemo(
    () => add(new Date(), { hours: 1 }),
    [selectValue]
  );
  const globalIsLoading = useMemo(
    () => isLoading || feesLoading,
    [isLoading, feesLoading]
  );

  const schema = yup
    .object({
      description: yup.string().required('Description required'),
      amount: yup.string().required('Amount required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const amountValue = watch('amount', '0');
  const goEscrowInputValue = watch('goescrow_id');

  const handleContinue = useCallback(() => {
    if (!isValid) return;

    setConfirmModalVisible(true);
  }, [isValid]);

  const handleLookupGoEscrowUser = useCallback(async () => {
    if (!goEscrowInputValue || goEscrowInputValue === '') return;

    setIsLoading(true);
    const res = await lookupGoEscrowUser(parseInputIfPhone(goEscrowInputValue));

    setIsLoading(false);
    if (res.status) {
      setLookupError('');
      setGoEscrowUser(res.data);
    } else {
      setGoEscrowUser('');

      if (!validatePhoneOrEmail(goEscrowInputValue)) {
        setLookupError('Invalid Email or Phone Number');
      } else {
        setLookupError('User not found. Invite instead?');
      }
    }
  }, [goEscrowInputValue]);

  const parseInputIfPhone = (input: string) => {
    if (input && input !== '' && input.match(/^[0-9]{8,}$/)) {
      return (
        phone(input, {
          country: 'AUS',
        }).phoneNumber ?? input
      );
    }
    return input;
  };

  const handleDateChange = useCallback((date: Date) => {
    setDateValue(date);
  }, []);

  const onSelectOption = useCallback((value: string) => {
    setSelectValue(value);
  }, []);

  const handleConfirmTransaction = useCallback(
    async (data: any) => {
      setIsLoading(true);
      const body = {
        ...data,
        transaction_type: 'send',
        type: selectValue === 'Anytime' ? 'anytime' : 'time',
        ...(selectValue === 'Timed Escrow' && {
          release_at: formatInTimeZone(
            dateValue,
            'UTC',
            DateTimeFormats.FormDateTime
          ),
        }),
        amount: parseFloat(data.amount).toFixed(2),
        other_user_reference: parseInputIfPhone(goEscrowInputValue),
        other_user_id: goEscrowUser?.id ?? '',
      };

      const res = await createDeal(body);

      if (res.status && res.data.id) {
        fetchAndNavigate(res.data.id);
      } else {
        setIsLoading(false);
        toastError();
      }
    },
    [createDeal]
  );

  const fetchAndNavigate = async (dealId: any) => {
    if (!dealId) {
      setIsLoading(false);
      return;
    }

    const res = await getDealDetails(dealId);
    setIsLoading(false);
    handleCloseModal();
    if (res.status) {
      dealNavigationHelper(res.data, userDetails, true);
    } else {
      toastError();
    }
  };

  const doStep2 = useCallback(() => {
    if (goEscrowUser && userDetails.id === goEscrowUser.id) {
      toastError('This is your account');
      return;
    }
    setStep2(true);
  }, [goEscrowUser, userDetails]);

  const handleCloseModal = useCallback(() => {
    setConfirmModalVisible(false);
  }, []);

  const resetStep1 = useCallback(() => {
    setGoEscrowUser(null);
    setLookupError('');
  }, []);

  const MainButton = useMemo(() => {
    if (goEscrowUser === null) return null;
    if (goEscrowUser === '' && !validatePhoneOrEmail(goEscrowInputValue))
      return null;

    let title = 'Send';
    let action = handleContinue;
    if (!isStep2) {
      title = goEscrowUser === '' ? 'Invite' : 'Next';
      action = doStep2;
    }
    return (
      <button
        className="btn-main mt-6  w-11/12 sm:w-2/4"
        onClick={action}
        disabled={isStep2 && !isValid}
      >
        {title}
      </button>
    );
  }, [goEscrowUser, handleContinue, doStep2, isValid, isStep2]);

  const totalPrice = useMemo(() => {
    const fee =
      parseFloat(
        selectValue === 'Anytime' ? feesData?.anytime : feesData?.time
      ) || 0;
    const amt = parseFloat(amountValue) || 0;
    return (amt + (fee / 100) * amt).toFixed(2);
  }, [amountValue, feesData, selectValue]);

  const userFullname = useMemo(
    () => `${userDetails?.first_name} ${userDetails?.last_name}`,
    [userDetails]
  );

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 bg-white py-6">
      <h2 className="text-2xl font-semibold text-primary">Send Funds</h2>

      {!isStep2 && <img src={sendFundsSVG} />}
      <div className="flex flex-col self-center py-5">
        <h2 className=" text-center text-lg font-semibold text-neutral-800">
          Start a Transaction
        </h2>

        <p className="text-center text-sm">
          Setup a safe and secure GoEscrow deal for yourself and for your
          seller. <br /> Receive your goods or services and only then authorise
          payment settlement to your seller.
        </p>
      </div>

      {!isStep2 && (
        <div className="flex w-11/12 flex-col gap-2 sm:w-2/4">
          <FormInput
            control={control}
            name="goescrow_id"
            label="Other Party Details"
            placeholder="GoEscrow ID, Phone or Email"
            labelClassname="text-lg text-primary"
            type="text"
            onChangeCapture={resetStep1}
          />

          {goEscrowUser !== '' && lookupError === '' && (
            <p className="pl-1 text-sm text-primary">
              User: {goEscrowUser?.name}
            </p>
          )}

          {lookupError && (
            <p className="bg-primary px-6 py-3 text-sm text-white">
              {lookupError}
            </p>
          )}

          {goEscrowUser === null && (
            <button
              className="btn-main mt-4"
              onClick={handleLookupGoEscrowUser}
              disabled={goEscrowInputValue === ''}
            >
              Check
            </button>
          )}
        </div>
      )}

      {isStep2 && (
        <div className="flex w-1/2 flex-col">
          <div className="flex flex-col gap-2">
            <label className="text-lg text-primary">GoESCROW Type</label>
            <Dropdown
              items={EscrowTypes}
              selected={selectValue}
              onChange={onSelectOption}
            />

            {selectValue === 'Timed Escrow' && (
              <ReactDatePicker
                selected={dateValue}
                onChange={handleDateChange}
                startOpen
                timeFormat="HH:mm"
                showTimeInput
                timeInputLabel="Time:"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="z-30 min-h-[44px] w-full appearance-none rounded-sm border border-gray-300 p-2 font-light text-gray-900 placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-primary"
              />
            )}
          </div>

          <div className="mt-4 flex flex-col gap-2">
            <label className="text-lg text-primary">Transaction Details</label>
            <FormInput
              control={control}
              name="description"
              placeholder="Description"
              type="textarea"
            />
            <FormInput
              control={control}
              name="amount"
              placeholder="Item Price"
              type="number"
              prefixComponent={<p className="text-primary">AUD$</p>}
            />
          </div>

          <div className="mt-4 flex flex-col self-end">
            <p className="text-right text-sm">
              {selectValue === 'Timed Escrow'
                ? `Fee ${feesData?.time}%`
                : `Fee ${feesData?.anytime}%`}
            </p>
            <h2 className="text-lg font-semibold text-primary">
              Total ${totalPrice}
            </h2>
          </div>
        </div>
      )}
      {MainButton}

      <Modal isOpen={isConfirmModalVisible} onClose={handleCloseModal}>
        <Dialog.Title
          as="h3"
          className="text-lg font-medium leading-6 text-primary"
        >
          Confirm Transaction
        </Dialog.Title>
        <div className="mt-2 flex flex-col gap-2">
          <div className="rounded border p-3">
            <p className="text-sm">
              <strong>From:</strong> {userFullname}
            </p>
          </div>
          <div className="rounded border p-3">
            <p className="text-sm">
              <strong>Amount:</strong> {totalPrice}
            </p>
          </div>
          <div className="rounded border p-3">
            <p className="text-sm">
              <strong>Fee:</strong>{' '}
              {(selectValue === 'Anytime'
                ? feesData?.anytime
                : feesData?.time) || 0}
              %
            </p>
          </div>
        </div>

        <div className="mt-8 flex flex-row-reverse items-end gap-4">
          <button
            className="btn-main"
            disabled={globalIsLoading}
            onClick={handleSubmit(handleConfirmTransaction)}
          >
            Confirm Transaction
          </button>

          <button
            onClick={handleCloseModal}
            className="rounded-lg bg-secondary py-3 px-4 font-bold text-white hover:bg-secondary-120"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SendFunds;
