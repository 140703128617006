export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  TWO_FACTOR: '/two-factor',
  TWO_FACTOR_MOBILE: '/two-factor-mobile',
  VERIFICATION_PROMPT: '/verification-prompt',
  VERIFICATION: '/verification',
  FORGOT_PASSWORD: '/forgot-password',

  HOME: '/home',
  SEND_FUNDS: '/send-funds',
  TRANSFER_PAYMENT: '/transfer-payment',
  TRANSACTION_DETAILS: '/transaction-details',
  INVITE_USER: '/invite-user',
  INVITED_DEAL: '/invited-deal',
  ACCEPT_DEAL: '/accept-deal',
  CHANGE_EMAIL: '/change-email',
  CHANGE_MOBILE: '/change-mobile',
  CHANGE_PASSWORD: '/change-password',

  REQUEST_FUNDS: '/request-funds',

  VIEW_DETAILS: '/account',
  TRANSACTIONS: '/transactions',
};
